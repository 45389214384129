import { AxiosError } from "axios";
import { REQUEST } from "../request";

const LOGIN_AUTH = async (token: string) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: `/auth`,
      method: "get",

      token,
    });

    return { result: req.data, status: req.status };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default LOGIN_AUTH;
