import { ToastContainer, toast } from "react-toastify";
import AsideDefault from "../../components/AsideDefault";
import ToolbarDefault from "../../components/Toolbars/ToolbarDefault";
import { SellerInfoContext } from "../../contexts/sellerInfoContext";
import "./style.css";
import { useContext, useEffect, useState } from "react";
import GET_STATUS_PROPOSAL from "../../utils/apis/getStatusProposal";
import { AuthContext } from "../../contexts/userContext";
import { ContractStatus } from "../../types/contractStatus";
import {
  formatDate,
  formatDateMonthParam,
  formatDateTodayParam,
} from "../../utils/formatDate";
import { copyPaste } from "../../utils/copyPaste";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";

const ProposalStatusPage = () => {
  const { sellerInfo } = useContext(SellerInfoContext);
  const { authToken } = useContext(AuthContext);
  const [salesName, setSalesName] = useState("");
  const [position, setPosition] = useState("");
  const [contractsList, setContractsList] = useState<ContractStatus[] | []>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  let authStorage = sessionStorage.getItem("@_auth");

  useEffect(() => {
    setIsLoading(true);
    if (authStorage === "null" || authStorage === null) {
      localStorage.clear();
      sessionStorage.clear();
      setIsLoading(false);
      return navigate("/");
    }

    localStorage.removeItem("_contract_details_id");

    const proposalStatus = async () => {
      const req = await GET_STATUS_PROPOSAL(
        1,
        20,
        formatDateMonthParam(),
        formatDateTodayParam(),
        authStorage
      );

      switch (req?.status) {
        case 200:
          setContractsList(req.result.contracts);
          if (!req.result.contracts.length) {
          }
          break;
        case 401:
          toast.error("Sua sessão expirou. Faça login para continuar.", {
            autoClose: 4000,
          });

          setTimeout(() => {
            navigate("/");
          }, 3000);
          break;
      }
    };

    proposalStatus();

    setSalesName(sellerInfo?.name);
    setPosition(sellerInfo?.desc_name);
    setIsLoading(false);
  }, []);

  const copyLink = (url: string) => {
    let copy = copyPaste(url);
    if (copy === "error") {
      toast.error("Erro ao tentar copiar o link. Por favor, tente novamente");
    } else {
      toast.success("Link copiado com sucesso!");
    }
  };

  const contractDetails = (id: number) => {
    localStorage.setItem("_contract_details_id", id?.toString());
    navigate("/dashboard/details-contract");
  };

  const singularWord = (word: string) => {
    let wordArr = word.split(" ");
    return `${wordArr[0].slice(0, -1)} ${wordArr[1].slice(0, -1)}`;
  };
  console.log(contractsList);
  return (
    <div className="proposal-status">
      <AsideDefault />
      <ToolbarDefault />
      <main className="main-container-proposal">
        <nav className="nav-container">
          <p>ACOMPANHAMENTO DE PROPOSTA</p>
          <div className="employee-details">
            <p className="employee-name">{salesName}</p>
            <p className="employee-position">{position}</p>
          </div>
        </nav>

        {isLoading || !contractsList.length ? (
          <Loading />
        ) : (
          <div className="table-info">
            <table className="main-table">
              <tbody className="main-table-body">
                <tr className="header-table">
                  <th className="th">ID</th>
                  <th className="th">CONTRATO</th>
                  <th className="th">CLIENTE</th>
                  <th className="th">R$ LIBERADO</th>
                  <th className="th">STATUS</th>
                  <th className="th">TX. JUROS</th>
                  <th className="th">TABELA</th>
                  <th className="th">ULT. ATUALIZAÇÃO</th>
                  {/* mock ------------->>> FOR MANAGER THIS FIELD IS ENABLED*/}
                  {/* <th className="th">VENDEDOR</th> */}
                  <th className="th actions-table-body">
                    COPIAR LINK FORMALIZAÇÃO
                  </th>
                  <th className="th actions-table-body">
                    DETALHES DO CONTRATO
                  </th>
                </tr>

                {contractsList?.map(
                  (contract: ContractStatus, index: number) => (
                    <tr key={index} className="simulation-row">
                      <td className="simulation-td">{contract.id}</td>
                      <td className="simulation-td">
                        {contract.contract_number}
                      </td>
                      <td className="simulation-td">{contract.name}</td>
                      <td className="simulation-td">
                        {Number(contract.contract_value).toLocaleString(
                          "pt-BR",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )}
                        {/* {contract.contract_value} */}
                      </td>
                      <td className={`simulation-td`}>
                        <span
                          className={`text-alert-${contract.status_original}`}
                        >
                          {contract.status_pipeline === "Contratos Pagos"
                            ? singularWord(contract.status_pipeline)
                            : contract.status_pipeline}
                        </span>
                      </td>
                      <td className="simulation-td">
                        {contract.basic_interest}
                      </td>
                      <td className="simulation-td">
                        {contract.tac_table_name}
                      </td>
                      <td className="simulation-td">
                        {formatDate(contract.updated_at)}
                      </td>
                      {/* mock ------------->>> FOR MANAGER THIS FIELD IS ENABLED*/}
                      {/*<td className="simulation-td">
                    {contract.seller_name}  
                  </td>*/}

                      {/* https://sistema.novosaque.com.br/api/v1/simulations/201641
Https://sistema.novosaque.com.br/api/v1/page_log/detalhes_de_contrato/201641 */}
                      <td>
                        <button
                          className="menu-icon-button"
                          onClick={() => copyLink(contract.onboarding_link)}
                        >
                          Copiar link
                        </button>
                      </td>
                      <td>
                        <button
                          className="menu-icon-button"
                          onClick={() => contractDetails(contract.id)}
                        >
                          Detalhes
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        )}
      </main>
    </div>
  );
};

export default ProposalStatusPage;
