import { useEffect, useState } from "react";
import "./style.css";
import GET_ENTITY from "../../../utils/apis/getEntity";
import { useNavigate } from "react-router-dom";
import AsideDefault from "../../../components/AsideDefault";
import ToolbarDefault from "../../../components/Toolbars/ToolbarDefault";
import DefaultNavBar from "../../../components/Navs/DefaultNavBar";
import DefaultInput from "../../../components/Inputs/DefaultInput";
import { CommissionFGTSInfo } from "../../../types/commissionFGTSInfo";

const CommissionFGTSDataPage = () => {
  const navigate = useNavigate();
  let authStorage = sessionStorage.getItem("@_auth");
  const [isLoading, setIsLoading] = useState(false);
  const [commissionListSmart, setCommissionListSmart] = useState<
    CommissionFGTSInfo[] | []
  >([]);
  const [commissionList, setCommissionList] = useState<
    CommissionFGTSInfo[] | []
  >([]);

  useEffect(() => {
    if (authStorage === "null" || authStorage === null) {
      localStorage.clear();
      sessionStorage.clear();
      setIsLoading(false);
      return navigate("/");
    }

    const commissionList = async () => {
      let req = await GET_ENTITY(authStorage);

      let commissionList = req?.result.fgts_commissions;

      setCommissionListSmart(
        commissionList.filter((commission: CommissionFGTSInfo) =>
          commission.name.includes("Smart")
        )
      );
      setCommissionList(
        commissionList.filter(
          (commission: CommissionFGTSInfo) => !commission.name.includes("Smart")
        )
      );
    };

    commissionList();
    setIsLoading(false);
  }, []);

  return (
    <div className="commission-card-data">
      <AsideDefault />
      <ToolbarDefault />
      <main className="main-container">
        <DefaultNavBar title="Dados de Comissão FGTS" />
        <h4 className="sub-text">Acima de R$ 250,00</h4>
        <div className="list-commission-fgts">
          {commissionList.map((commission, index) => (
            <div className="table-list-commission" key={index}>
              <DefaultInput readOnly type="text" value={commission.name} />
              <DefaultInput
                readOnly
                type="text"
                value={`${Number(commission.rate).toFixed(2)}%`}
              />
            </div>
          ))}
        </div>
        <h4 className="sub-text">De R$ 100,00 a R$ 249,99</h4>
        <div className="list-commission-fgts">
          {commissionListSmart.map((commission, index) => (
            <div className="table-list-commission" key={index}>
              <DefaultInput readOnly type="text" value={commission.name} />
              <DefaultInput
                readOnly
                type="text"
                value={`${Number(commission.rate).toFixed(2)}%`}
              />
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

export default CommissionFGTSDataPage;
