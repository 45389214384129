import { AxiosError } from "axios";
import { REQUEST } from "../request";

const GET_PRE_REGISTERS = async () => {
  try {
    const req = await REQUEST({
      urlBase: "nyc",
      endPoint: `/PreRegister/getPreRegisters`,
      method: "get",
    });

    return { result: req.data, status: req.status };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default GET_PRE_REGISTERS;
