export const formatDate = (date: Date | undefined) => {
  if (date !== undefined) {
    date = new Date(date);
    let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    let month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    return `${day}/${month}/${date.getFullYear()}`;
  } else {
    return date;
  }
};

export const formatDateTodayParam = () => {
  let date = new Date();
  let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  let month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  return `${date.getFullYear()}-${month}-${day}`;
};

export const formatDateMonthParam = () => {
  let date = new Date();
  let month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  return `${date.getFullYear()}-${month}-01`;
};
