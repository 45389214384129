import { AxiosError } from "axios";
import { REQUEST } from "../request";

const POST_CHECK_CPF_BALANCE_FGTS = async (cpf: string, token: string) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: `/simulations/check_cpf_balance_fgts`,
      method: "post",
      token,
      data: { cpf },
    });

    return { result: req.data, status: req.status };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default POST_CHECK_CPF_BALANCE_FGTS;
