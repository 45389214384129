import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { ClientServiceContext } from "../../contexts/clientServiceContext";
import DefaultInput from "../../components/Inputs/DefaultInput";
import VMasker from "vanilla-masker";
import { CEPMask } from "../../utils/masks";
import { Property } from "../../types/property";
import { banks } from "../../mocks/banksMock";
import DefaultButton from "../../components/Buttons/DefaultButton";
import VALID_EMAIL from "../../utils/apis/validEmail";
import { ToastContainer, toast } from "react-toastify";
import CUSTOMER_CREATE from "../../utils/apis/customerCreate";
import { Customer } from "../../types/customer";
import { useNavigate } from "react-router-dom";
import AsideDefault from "../../components/AsideDefault";
import { GET_CEP } from "../../utils/apis/getCEP";
import ToolbarDefault from "../../components/Toolbars/ToolbarDefault";
import { CostumerContext } from "../../contexts/costumerContext";
import REGISTER_CONTRACT_BY_CUSTOMER_SERVICE_ID from "../../utils/apis/registerContractByCustomerServiceId";
import { SimulationContext } from "../../contexts/simulationContext";
import CUSTOMER_BY_ID from "../../utils/apis/getCustomerById";
import CONTRACT_DETAILS_BY_DOC from "../../utils/apis/contractDetailsInfoByDoc";

const AddClientPage = () => {
  const { clientServiceInfo } = useContext(ClientServiceContext);
  const { simulationInfo } = useContext(SimulationContext);
  const { addCostumerInfo, costumerInfo } = useContext(CostumerContext);

  const navigate = useNavigate();
  const [serviceCPF, setServiceCPF] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceEmail, setServiceEmail] = useState("");
  const [servicePhone, setServicePhone] = useState("");
  const [motherName, setMotherName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [document, setDocument] = useState("");
  const [gender, setGender] = useState("");
  const [CEPNumber, setCEPNumber] = useState("");
  const [clientAddress, setClientAddress] = useState<Property>({
    zipcode: "",
    state: "",
    neighbourhood: "",
    city: "",
    address: "",
    number: "",
    complement: "",
  });
  const [complement, setComplement] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankNumber, setBankNumber] = useState("");
  const [bankAgency, setBankAgency] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [accountDigit, setAccountDigit] = useState("");
  const [accountType, setAccountType] = useState("0");
  const [isLoading, setIsLoading] = useState(false);

  let authStorage = sessionStorage.getItem("@_auth");
  let customerIdStorage = localStorage.getItem("customer_id");

  useEffect(() => {
    if (authStorage === "null" || authStorage === null) {
      localStorage.clear();
      sessionStorage.clear();
      return navigate("/");
    }

    const customerDetails = async () => {
      let customer = await CONTRACT_DETAILS_BY_DOC(
        clientServiceInfo?.cpf_cnpj as string,
        authStorage as string
      );

      let a: any = customer?.result[0];

      setServiceCPF(a?.cpf_cnpj);
      setServiceName(a?.name);
      setServiceEmail(a?.email);
      setServicePhone(a?.phone || a?.mobile);
      setMotherName(a?.mother_name);
      setBirthDate(a?.birth_date + " 00:00:00");
      setDocument(a?.rg);
      setGender(a?.gender_customer);
      setCEPNumber(a?.entity?.address?.zip_code);
      setClientAddress({
        zipcode: a?.entity?.address?.zip_code,
        state: a?.entity?.address?.state,
        neighbourhood: a?.entity?.address?.district,
        city: a?.entity?.address?.city,
        address: a?.entity?.address?.street,
        number: a?.entity?.address?.number,
        complement: a?.entity?.address?.complement,
      });
      setComplement(a?.entity?.address?.complement);
      setAddressNumber(a?.entity?.address?.number);
      setBankName(a?.entity?.bank_account?.name_bank);
      setBankNumber(
        banks.find((bank) => bank?.name === a?.entity?.bank_account?.name_bank)
          ?.code || ""
      );
      setBankAgency(a?.entity?.bank_account?.agency_account);
      setBankAccount(a?.entity?.bank_account?.number_account);
      setAccountDigit(a?.entity?.bank_account?.account_digit);
      setAccountType(a?.entity?.bank_account?.kind);
    };
    customerDetails();
  }, []);

  const serviceCPFHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    VMasker(e.target).unMask();
    VMasker(e.target).maskPattern("999.999.999-99");
    setServiceCPF(e.target.value);
  };

  const servicePhoneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    VMasker(e.target).unMask();
    VMasker(e.target).maskPattern("(99) 9 9999-9999");
    setServicePhone(e.target.value);
  };

  const maskCEP = async (event: React.ChangeEvent<HTMLInputElement>) => {
    CEPMask(event);
    let rawCep = event.target.value;

    setCEPNumber(rawCep);
    if (rawCep.length >= 8) {
      let res = await GET_CEP(rawCep);

      setClientAddress({
        // @ts-ignore
        zipcode: res.cep,
        // @ts-ignore
        address: res.logradouro,
        // @ts-ignore
        city: res.localidade,
        // @ts-ignore
        complement: "",
        // @ts-ignore
        neighbourhood: res.bairro,
        number: "",
        // @ts-ignore
        state: res.uf,
      });
    }
  };

  const createClientSubmit = async () => {
    setIsLoading(true);

    if (
      !serviceName ||
      !serviceEmail ||
      !birthDate ||
      !motherName ||
      !serviceCPF ||
      !document ||
      !servicePhone ||
      !CEPNumber ||
      !clientAddress.address ||
      !addressNumber ||
      !clientAddress.neighbourhood ||
      !clientAddress.city ||
      !clientAddress.state ||
      !bankAgency ||
      !bankName ||
      !bankAccount ||
      !accountDigit ||
      !gender
    ) {
      let emptyField: string | null = null;

      if (!accountDigit) emptyField = "Dígito da conta";
      if (!bankAccount) emptyField = "Conta bancária";
      if (!bankAgency) emptyField = "Agência bancária";
      if (!bankName) emptyField = "Nome do banco";
      if (!clientAddress.state) emptyField = "Estado";
      if (!clientAddress.city) emptyField = "Cidade";
      if (!clientAddress.neighbourhood) emptyField = "Bairro";
      if (!addressNumber) emptyField = "Número do endereço";
      if (!clientAddress.address) emptyField = "Endereço";
      if (!CEPNumber) emptyField = "CEP";
      if (!servicePhone) emptyField = "Celular";
      if (!document) emptyField = "Documento RG";
      if (!serviceCPF) emptyField = "CPF";
      if (!motherName) emptyField = "Nome da mãe";
      if (!birthDate) emptyField = "Data de nascimento";
      if (!serviceEmail) emptyField = "Email";
      if (!serviceName) emptyField = "Nome";
      if (!gender) emptyField = "Sexo";

      setIsLoading(false);
      return toast.warning(
        `O campo "${emptyField?.toUpperCase()}" não foi preenchido`
      );
    }

    let customer: Customer = {
      customer_service_id: clientServiceInfo?.id,

      customer: {
        birth_date: birthDate,
        email: serviceEmail,
        mobile: servicePhone,
        rg: document,
        father_name: null,
        gender_customer: gender,
        marital_status: "married",
        mother_name: motherName,
        entity_attributes: {
          name: serviceName,
          cpf_cnpj: serviceCPF,
          address_attributes: {
            zip_code: clientAddress.zipcode as string,
            street: clientAddress.address as string,
            number: addressNumber,
            district: clientAddress.neighbourhood as string,
            city: clientAddress.city as string,
            state: clientAddress.state as string,
            complement: complement,
          },
          bank_account_attributes: {
            account_digit: Number(accountDigit),
            agency_account: bankAgency,
            agency_digit: 0,
            kind: Number(accountType),
            kind_account: "pix",
            month: "",
            name_bank: bankName,
            number_account: bankAccount,
            number_bank: bankNumber,
          },
        },
      },
    };

    // let registerContractReq = await REGISTER_CONTRACT_BY_CUSTOMER_SERVICE_ID(
    //   customer,
    //   simulationInfo?.id,
    //   clientServiceInfo?.id,
    //   authStorage as string
    // );

    let reqValidEmail = await VALID_EMAIL(serviceEmail, authStorage as string);

    switch (reqValidEmail?.status) {
      case 200:
        let reqCustomerCreate = await CUSTOMER_CREATE(
          customer,
          authStorage as string
        );

        addCostumerInfo(customer);

        toast.success("Cliente cadastrado com sucesso!");
        localStorage.setItem("link_clicked", "search-contract");
        setTimeout(() => {
          navigate("/dashboard/confirm-contract");
        }, 1000);
        break;
      case 401:
        toast.error("Sua sessão expirou. Faça login para continuar.", {
          autoClose: 4000,
        });

        setTimeout(() => {
          navigate("/");
        }, 3000);

        break;
      default:
        toast.error(
          "Houve um erro ao validar o email do cliente. Por favor verifique e tente novamente."
        );
        break;
    }
    setIsLoading(false);
  };

  return (
    <div className="add-client-page">
      <AsideDefault />
      <ToolbarDefault />
      <div className="add-client-form-main">
        <h3 className="text-header">Criar Cliente</h3>
        <div className="default-form personal-information">
          <h4>Informações pessoais</h4>
          <DefaultInput
            name="name"
            onChange={(e) => setServiceName(e.target.value)}
            type="text"
            value={serviceName}
            label="Nome Completo"
            placeholder="Fulano da Silva"
          />
          <DefaultInput
            name="email"
            onChange={(e) => setServiceEmail(e.target.value)}
            type="email"
            value={serviceEmail}
            label="Email"
            placeholder="exemplo@email.com"
          />
          <DefaultInput
            name="motherName"
            onChange={(e) => setMotherName(e.target.value)}
            type="text"
            value={motherName}
            label="Nome da Mãe"
            placeholder="Senhora da Silva"
          />

          <DefaultInput
            name="birthDate"
            onChange={(e) => setBirthDate(e.target.value)}
            type="date"
            value={birthDate}
            label="Data de Nascimento"
            placeholder="01/01/1900"
          />
          <DefaultInput
            name="cpf"
            onChange={serviceCPFHandler}
            type="text"
            value={serviceCPF}
            label="CPF"
            placeholder="123.456.789-10"
          />
          <DefaultInput
            name="document"
            onChange={(e) => setDocument(e.target.value)}
            type="text"
            value={document}
            label="RG"
            placeholder="12.345.678-9"
          />
          <DefaultInput
            name="phone"
            onChange={servicePhoneHandler}
            type="tel"
            value={servicePhone}
            label="Celular"
            placeholder="(99) 9 9999-9999"
          />
          <label className="label-gender" htmlFor="select-gender">
            Sexo
            <select
              onChange={(e) => setGender(e.target.value)}
              id="select-gender"
              name="select-gender"
              className="select-gender"
            >
              <option
                selected={!clientServiceInfo?.gender_customer}
                value=""
              ></option>
              <option
                selected={clientServiceInfo?.gender_customer === "MASCULINO"}
                value="MASCULINO"
              >
                Masculino
              </option>
              <option
                selected={clientServiceInfo?.gender_customer === "FEMININO"}
                value="FEMININO"
              >
                Feminino
              </option>
            </select>
          </label>
        </div>
        <hr />
        <div className="default-form address-information">
          <h4>ENDEREÇO DO CLIENTE</h4>
          <DefaultInput
            name="CEP"
            onChange={maskCEP}
            type="text"
            value={CEPNumber}
            label="CEP"
            placeholder="00000-000"
          />
          <div className="address-number">
            <DefaultInput
              name="Endereço"
              onChange={(e) =>
                setClientAddress({
                  ...clientAddress,
                  address: e.target.value,
                })
              }
              type="text"
              value={clientAddress.address}
              label="Endereço"
              placeholder="Rua das Flores"
            />
            <DefaultInput
              name="number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAddressNumber(e.target.value)
              }
              type="text"
              value={addressNumber}
              label="Número"
              placeholder="000"
            />
          </div>
          <DefaultInput
            name="neighborhood"
            onChange={(e) =>
              setClientAddress({
                ...clientAddress,
                neighbourhood: e.target.value,
              })
            }
            type="text"
            value={clientAddress.neighbourhood}
            label="Bairro"
            placeholder="Bairro Alto"
          />
          <DefaultInput
            name="city"
            onChange={(e) =>
              setClientAddress({
                ...clientAddress,
                city: e.target.value,
              })
            }
            type="text"
            value={clientAddress.city}
            label="Cidade"
            placeholder="São Paulo"
          />
          <DefaultInput
            name="UF"
            onChange={(e) =>
              setClientAddress({
                ...clientAddress,
                state: e.target.value,
              })
            }
            type="text"
            value={clientAddress.state}
            label="UF"
            placeholder="SP"
          />
          <DefaultInput
            name="complement"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setComplement(e.target.value)
            }
            type="text"
            value={complement}
            label="Complemento"
            placeholder="Casa"
          />
        </div>
        <hr />
        <div className="default-form address-information">
          <h4>DADOS DE PAGAMENTO</h4>
          <label className="label-bank-name" htmlFor="select-bank-name">
            Nome do Banco
            <select
              onChange={(e) => {
                setBankName(JSON.parse(e.target?.value)?.name);
                setBankNumber(JSON.parse(e.target?.value)?.code);
              }}
              id="select-bank-name"
              name="select-bank-name"
              className="select-bank-name"
            >
              <option selected>{bankName}</option>
              {banks.map((bank, index) => (
                <option
                  value={JSON.stringify(bank)}
                  className="option-bank-name"
                  key={index}
                >
                  {bank.name}
                </option>
              ))}
            </select>
          </label>
          <DefaultInput
            name="agency"
            onChange={(e) => setBankAgency(e.target.value)}
            type="text"
            value={bankAgency}
            label="Agência"
            placeholder="00000"
          />
          <DefaultInput
            name="account"
            onChange={(e) => setBankAccount(e.target.value)}
            type="text"
            value={bankAccount}
            label="Conta"
            placeholder="0000000"
          />
          <DefaultInput
            name="digit"
            onChange={(e) => setAccountDigit(e.target.value)}
            type="text"
            value={accountDigit}
            label="Dígito"
            placeholder="00"
          />
          <label className="label-account-type" htmlFor="select-account-type">
            Tipo de Conta
            <select
              onChange={(e) => setAccountType(e.target.value)}
              id="select-account-type"
              name="select-account-type"
              className="select-account-type"
            >
              <option
                selected={clientServiceInfo?.bank_account?.kind === 0}
                value={"0"}
                className="option-account-type"
              >
                Conta Corrente
              </option>
              <option
                selected={clientServiceInfo?.bank_account?.kind === 1}
                value={"1"}
                className="option-account-type"
              >
                Poupança
              </option>
            </select>
          </label>
          <DefaultButton
            text="Adicionar cliente"
            onClick={createClientSubmit}
            isSubmit={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddClientPage;
