import axios, { AxiosError } from "axios";

export const GET_CEP = async (rawCep: string) => {
  try {
    let req = await axios({
      method: "get",
      url: `https://viacep.com.br/ws/${rawCep}/json/`,
    });

    return req.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};
