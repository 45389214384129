import "./style.css";

const FinancialDataPage = () => {
  return (
    <div className="financial-data">
      <p>FinancialDataPage</p>
    </div>
  );
};

export default FinancialDataPage;
