import { AxiosError } from "axios";
import { REQUEST } from "../request";

type BodyRequest = {
  contract: {
    name: string;
    store_name: any;
    contract_value: number;
    amount_charged: number;
    kind_integrator: number;
    installments: number;
    customer_id: number;
    customer_attributes: {
      email: string;
      birth_date: string;
      mobile: string;
      rg: string;
      entity_attributes: {
        name: string;
        cpf_cnpj: string;
        address_attributes: {
          zip_code: string;
          street: string;
          number: string;
          district: string;
          city: string;
          state: string;
          complement: string;
        };
      };
    };
  };
};

const REGISTER_CONTRACT = async (
  data: BodyRequest,
  simulation_id: string,
  token: string
) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: `/contracts?simulation_id=${simulation_id}`,
      method: "post",
      token,
      data,
    });

    return {
      result: req.data,
      status: req.status,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default REGISTER_CONTRACT;
