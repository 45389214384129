export const copyPaste = (content: string | undefined) => {
  if (!content) {
    return "error";
  }

  let textArea = document.createElement("textarea");
  textArea.value = content;
  textArea.style.opacity = "0";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand("copy");

  document.body.removeChild(textArea);
  return "success";
};
