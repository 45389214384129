import "./style.css";

type DefaultSelectProps = {
  htmlFor: string;
  onChange?: React.ChangeEventHandler<HTMLSelectElement> | undefined;
  name?: string;
  options: OptionsProps[];
  labelText: string;
  undefinedValueText: string;
};

type OptionsProps = {
  selected?: boolean;
  value: string | number | readonly string[] | undefined;
  text: string | number;
};

const DefaultSelect = (props: DefaultSelectProps) => {
  const { htmlFor, onChange, name, options, labelText, undefinedValueText } =
    props;
  return (
    <label className="default-select-label" htmlFor={htmlFor}>
      {labelText}
      <select
        onChange={onChange}
        id={htmlFor}
        name={name}
        className="default-select-select"
      >
        <option value="default-select-option ">{undefinedValueText}</option>
        {options?.map((option, index) => (
          <option
            className="default-select-option"
            key={index}
            value={option.value}
            selected={option.selected}
          >
            {option.text}
          </option>
        ))}
      </select>
    </label>
  );
};

export default DefaultSelect;
