import loading from "../../assets/loading-icon-black.svg";
import "./style.css";

const Loading = () => {
  return (
    <div className="loading-icon-container">
      <img src={loading} className="loading-component" alt="loading icon" />
    </div>
  );
};

export default Loading;
