import { AxiosError } from "axios";
import { REQUEST } from "../request";

const CLIENT_DETAILS_BY_ID = async (infoId: string | number, token: string) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: `/simulations/${infoId}`,
      method: "get",

      token,
    });

    return { result: req.data, status: req.status };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default CLIENT_DETAILS_BY_ID;
