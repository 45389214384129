import { useContext, useEffect, useState } from "react";
import "./style.css";
import DefaultInput from "../../components/Inputs/DefaultInput";
import DefaultButton from "../../components/Buttons/DefaultButton";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/userContext";
import VMasker from "vanilla-masker";
import BALANCE_FGTS from "../../utils/apis/balanceFGTS";
import "react-toastify/dist/ReactToastify.css";
import CREATE_FGTS from "../../utils/apis/createFGTS";
import { ClientServiceContext } from "../../contexts/clientServiceContext";
import SIMULATIONS_LIST from "../../utils/apis/simulationsList";
import { SellerInfoContext } from "../../contexts/sellerInfoContext";
import SELLERS_LIST from "../../utils/apis/getSellersList";
import CLIENTS_DETAILS_BY_ID from "../../utils/apis/detailsInfoById";
import AsideDefault from "../../components/AsideDefault";
import ToolbarDefault from "../../components/Toolbars/ToolbarDefault";
import Modal from "react-modal";
import { formatDate } from "../../utils/formatDate";
import GET_ENABLED_FGTS from "../../utils/apis/getEnabledFGTS";
import { EnabledFGTS } from "../../types/enabledFGTS";
import REGISTER_SERVICE from "../../utils/apis/registerService";
import { RegisterService } from "../../types/registerService";
import { SimulationContext } from "../../contexts/simulationContext";
import Loading from "../../components/Loading";
import DefaultSelect from "../../components/Selects/DefaultSelect";
import { SimpleSellerInfo } from "../../types/simpleSellerInfo";
import { SellersListContext } from "../../contexts/sellersListContext";
import DefaultNavBar from "../../components/Navs/DefaultNavBar";
import POST_CHECK_CPF_BALANCE_FGTS from "../../utils/apis/postCheckCPFBalanceFGTS";
import CONTRACT_DETAILS_BY_DOC from "../../utils/apis/contractDetailsInfoByDoc";
import COMMISSIONS_FGTS from "../../utils/apis/comissionsFgts";

const WithdrawFGTSPage = () => {
  const { sellerInfo } = useContext(SellerInfoContext);
  const { addSimulationInfo } = useContext(SimulationContext);
  const { sellerList: sellerListContext } = useContext(SellersListContext);

  const { addClientInfo, removeClientInfo, clientServiceInfo } =
    useContext(ClientServiceContext);
  const navigate = useNavigate();

  const [contractSearch, seContractSearch] = useState("");
  const [processStep, setProcessStep] = useState<
    "initial" | "values" | "plans" | "complete-info" | ""
  >("initial");
  const [serviceCPF, setServiceCPF] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [serviceEmail, setServiceEmail] = useState("");
  const [servicePhone, setServicePhone] = useState("");
  const [creditConditionsMoney, setCreditConditionsMoney] = useState([]);
  const [creditConditionsMoneyInsurence, setCreditConditionsMoneyInsurence] =
    useState([]);
  const [creditConditionsLaranja, setCreditConditionsLaranja] = useState([]);
  const [
    creditConditionsLaranjaInsurence,
    setCreditConditionsLaranjaInsurence,
  ] = useState([]);
  const [creditConditionsGold, setCreditConditionsGold] = useState([]);
  const [creditConditionsGoldInsurence, setCreditConditionsGoldInsurence] =
    useState([]);
  const [creditConditionsSmart, setCreditConditionsSmart] = useState([]);
  const [creditConditionsSmartInsurence, setCreditConditionsSmartInsurence] =
    useState([]);
  const [conditionSelected, setConditionSelected] = useState("");
  const [releasedValue, setReleasedValue] = useState("");
  const [finishPersonalInfo, setFinishPersonalInfo] = useState(false);
  const [completeFGTSBalance, setCompleteFGTSBalance] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [simulationsList, setSimulationsList] = useState([]);
  const [sellerPhone, setSellerPhone] = useState("");
  const [sellersList, setSellersList] = useState<any[] | []>([]);
  const [selectedSeller, setSelectedSeller] = useState(0);
  const [isPromoter, setIsPromoter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enabledFGTS, setEnabledFGTS] = useState<EnabledFGTS>();
  const [customerId, setCustomerId] = useState(0);

  let authStorage = sessionStorage.getItem("@_auth");
  let isStore = sessionStorage.getItem("@store");

  useEffect(() => {
    setIsLoading(true);
    if (authStorage === "null" || authStorage === null) {
      localStorage.clear();
      sessionStorage.clear();
      setIsLoading(false);
      return navigate("/");
    }

    setSellersList(
      sellerListContext?.filter(
        (seller: any) => seller.status_original === "active"
      )
    );

    setIsPromoter(JSON.parse(isStore as string));

    const reqSimulationsList = async () => {
      let req = await SIMULATIONS_LIST(pageNumber, authStorage);

      setSimulationsList(req?.result);
    };

    const reqEnabledFgts = async () => {
      let req = await GET_ENABLED_FGTS(sellerInfo?.id, authStorage);

      setEnabledFGTS(req?.result);
    };

    reqSimulationsList();
    reqEnabledFgts();
    removeClientInfo();

    setIsLoading(false);
  }, []);

  const consultSubmit = async (
    step: "initial" | "values" | "plans" | "complete-info"
  ) => {
    setIsLoading(true);
    setConditionSelected("");
    // setCompleteFGTSBalance(undefined);
    let releaseValueReq;
    releaseValueReq = releasedValue
      ?.replace("R$ ", "")
      ?.replaceAll(".", "")
      ?.replace(",", ".");

    let reqCustomerById = await CONTRACT_DETAILS_BY_DOC(
      serviceCPF,
      authStorage as string
    );
    setCustomerId(reqCustomerById?.result[0]?.id);

    if (releaseValueReq === "0.00" || !releasedValue) {
      releaseValueReq = null;
    } else {
      releaseValueReq = Number(releaseValueReq);
    }

    const req = await BALANCE_FGTS(
      serviceCPF?.replace(/\D/g, "")?.trim(),
      releaseValueReq,
      authStorage as string,
      selectedSeller
    );

    setCompleteFGTSBalance(req?.res);

    let checkCPFBalance = await POST_CHECK_CPF_BALANCE_FGTS(
      serviceCPF?.replace(/\D/g, "")?.trim(),
      authStorage as string
    );

    console.log(checkCPFBalance);

    if (checkCPFBalance?.result.data?.cpf === true) {
      setIsLoading(false);
      return toast.warning("Usuário não possui saldo liberado para saque");
    }

    switch (req?.status) {
      case 200:
        let allCreditCondition = req?.res.CondicoesCredito.ListaCondicaoCredito;

        setCreditConditionsMoney([]);
        setCreditConditionsLaranja([]);
        setCreditConditionsGold([]);

        setCreditConditionsMoneyInsurence([]);
        setCreditConditionsLaranjaInsurence([]);
        setCreditConditionsGoldInsurence([]);

        setCreditConditionsSmart([]);
        setCreditConditionsSmartInsurence([]);

        if (Number(allCreditCondition[0].ValorCliente) >= 250) {
          setCreditConditionsMoney(
            allCreditCondition.filter(
              (credit: any) =>
                credit.Convenio.DescricaoConvenio ===
                  "TAB PADRAO FGTS TX 1,79 TC 12% - 30%" ||
                credit.Convenio.DescricaoConvenio ===
                  "TAB PADRAO FGTS TX 1,69 TC 12% - 30%" ||
                credit.Convenio.DescricaoConvenio ===
                  "TAB PADRAO FGTS TX 1,55 TC 12%-30%"
            )
          );

          setCreditConditionsLaranja(
            allCreditCondition.filter(
              (credit: any) =>
                credit.Convenio.DescricaoConvenio ===
                "TAB ESP FGTS TX 1.79 TC 15% - 30%"
            )
          );

          setCreditConditionsGold(
            allCreditCondition.filter(
              (credit: any) =>
                credit.Convenio.DescricaoConvenio ===
                "TAB ESP FGTS TX 1.79 TC 18% - 30%"
            )
          );

          setCreditConditionsMoneyInsurence(
            allCreditCondition.filter(
              (credit: any) =>
                credit.Convenio.DescricaoConvenio ===
                  "TAB SEGURO FGTS TX 1.79 TC 12% - 30%" ||
                credit.Convenio.DescricaoConvenio ===
                  "TAB SEGURO FGTS TX 1.69 TC 12% - 30%" ||
                credit.Convenio.DescricaoConvenio ===
                  "TAB SEGURO FGTS TX 1.55 TC 12% - 30%"
            )
          );

          setCreditConditionsLaranjaInsurence(
            allCreditCondition.filter(
              (credit: any) =>
                credit.Convenio.DescricaoConvenio ===
                "TAB SEGURO FGTS TX 1.79 TC 15% - 30%"
            )
          );

          setCreditConditionsGoldInsurence(
            allCreditCondition.filter(
              (credit: any) =>
                credit.Convenio.DescricaoConvenio ===
                "TAB SEGURO FGTS TX 1.79 TC 18% - 30%"
            )
          );
        } else {
          setCreditConditionsSmart(
            allCreditCondition.filter(
              (credit: any) =>
                credit.Convenio.DescricaoConvenio ===
                  "TAB PADRAO FGTS TX 1,79 TC 12% - 30%" ||
                credit.Convenio.DescricaoConvenio ===
                  "TAB PADRAO FGTS TX 1,69 TC 12% - 30%" ||
                credit.Convenio.DescricaoConvenio ===
                  "TAB PADRAO FGTS TX 1,55 TC 12%-30%"
            )
          );
        }

        let arr = [];
        arr.push(
          allCreditCondition.filter(
            (credit: any) =>
              credit.Convenio.DescricaoConvenio ===
              "TAB SEGURO FGTS TX 1.55 TC 12% - 30%"
          )
        );

        setCreditConditionsSmartInsurence(
          allCreditCondition
            .filter(
              (credit: any) =>
                credit.Convenio.DescricaoConvenio ===
                  "TAB SEGURO FGTS TX 1.55 TC 12% - 30%" ||
                credit.Convenio.DescricaoConvenio ===
                  "TAB SEGURO FGTS TX 1.69 TC 12% - 30%" ||
                credit.Convenio.DescricaoConvenio ===
                  "TAB SEGURO FGTS TX 1.79 TC 15% - 30%"
            )
            .sort((a: any, b: any) => {
              return b.TaxaCLAM - a.TaxaCLAM;
            })
        );

        setProcessStep(step);
        break;
      case 422:
        setConditionSelected("");
        //@ts-ignore
        toast.warning(req?.result?.response.data.data.message);

        break;
      default:
        toast.error(
          "Ocorreu um erro na consulta do cpf. Por favor, tente novamente."
        );
        break;
    }
    setIsLoading(false);
  };

  const serviceCPFHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    VMasker(e.target).unMask();
    VMasker(e.target).maskPattern("999.999.999-99");
    setServiceCPF(e.target.value);
  };

  const servicePhoneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    VMasker(e.target).unMask();
    VMasker(e.target).maskPattern("(99) 9 9999-9999");
    setServicePhone(e.target.value);
  };

  const selectedCondition = (condition: any) => {
    setProcessStep("plans");

    setConditionSelected(condition);
  };

  const releasedValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    VMasker(e.target).unMask();
    VMasker(e.target).maskMoney({
      precision: 2,
      separator: ",",
      delimiter: ".",
      unit: "R$",
    });

    setReleasedValue(e.target.value as string);
  };

  const createFGTS = async () => {
    setIsLoading(true);

    if (!serviceName || !serviceEmail || !servicePhone) {
      let emptyField: string | null = null;
      if (!serviceName) emptyField = "Nome";
      if (!serviceEmail) emptyField = "Email";
      if (!servicePhone) emptyField = "telefone";
      setIsLoading(false);
      return toast.warning(`O campo ${emptyField} não foi preenchido.`);
    }

    await consultSubmit("complete-info");

    const submitObj = {
      simulation: {
        name: serviceName,
        // @ts-ignore
        interest_rate: conditionSelected?.TaxaCLAM?.toString(),
        // @ts-ignore
        email: serviceEmail,
        cpf_cnpj: serviceCPF,
        // @ts-ignore
        phone: servicePhone,
        tac_percent: 30,
        // @ts-ignore
        tac: conditionSelected?.Despesas?.ListaDespesa[0]?.Valor,
        parcels: JSON.stringify({
          total_amount:
            // @ts-ignoreid
            conditionSelected?.Parcelas?.ListaParcela[0]?.ValorParcela,
          release_amount:
            // @ts-ignore
            conditionSelected?.Parcelas?.ListaParcela[0]?.ValorParcela,
          due_date:
            // @ts-ignore
            conditionSelected?.Parcelas?.ListaParcela[0]?.DataVencimento,
        }),
        simulation_fgts: JSON.stringify(conditionSelected),
        simulation_json: JSON.stringify(completeFGTSBalance),
        // @ts-ignore
        released_amount: conditionSelected?.ValorSolicitado,
        // @ts-ignore
        card_limit: conditionSelected?.ValorBruto,
        value_installment:
          // @ts-ignore
          conditionSelected?.Parcelas?.ListaParcela[0]?.ValorParcela,
        // @ts-ignore
        installments: conditionSelected?.QuantidadeParcelas,
        birth_date: "1980-05-20",
      },
    };

    const commissionFGTS = await COMMISSIONS_FGTS(
      {
        //@ts-ignore
        rate: conditionSelected?.TaxaCLAM.toString(),
        //@ts-ignore
        tac: conditionSelected?.Despesas?.ListaDespesa[0]?.Valor,
        //@ts-ignore
        value: Number(conditionSelected?.ValorSolicitado),
      },
      authStorage as string
    );

    let reqCreate = await CREATE_FGTS(submitObj, authStorage as string);
    let reqCustomerById = await CONTRACT_DETAILS_BY_DOC(
      serviceCPF,
      authStorage as string
    );

    // const serviceObj: RegisterService = {
    //   kind: releasedValue === "" ? "value_free" : releasedValue,
    //   simulation: {
    //     name: serviceName,
    //     cpf_cnpj: serviceCPF,
    //     email: serviceEmail,
    //     phone: servicePhone,
    //     // @ts-ignore
    //     interest_rate: conditionSelected?.TaxaCLAM?.toString(),
    //     // @ts-ignore
    //     installments: Number(conditionSelected?.QuantidadeParcelas),
    //     // @ts-ignore
    //     released_amount: Number(conditionSelected?.ValorSolicitado),
    //     // @ts-ignore
    //     card_limit: Number(conditionSelected?.ValorBruto),
    //     value_installment: Number(
    //       // @ts-ignore
    //       conditionSelected?.Parcelas?.ListaParcela[0]?.ValorParcela
    //     ),
    //     // @ts-ignore
    //     value_iof: conditionSelected.ValorIOF,
    //   },
    // };

    // let registerService = await REGISTER_SERVICE(
    //   serviceObj,
    //   authStorage as string
    // );

    console.log(reqCreate?.result);
    switch (reqCreate?.status) {
      case 200:
        toast.success("Atendimento realizado!");
        localStorage.setItem(
          "customer_id",
          reqCustomerById?.result?.customer_id
        );

        addSimulationInfo(reqCreate?.result);
        addClientInfo(reqCustomerById?.result[0]);
        setProcessStep("");

        return navigate("/dashboard/add-client");

      case 401:
        toast.error("Sua sessão expirou. Faça login para continuar.", {
          autoClose: 4000,
        });

        return navigate("/");

      default:
        toast.error(
          "Ocorreu um erro na consulta do CPF. Por favor tente novamente"
        );
        break;
    }
    setIsLoading(false);
  };

  const clientRegistrationDiscover = async () => {
    if (serviceCPF.length === 14) {
      let req = await CONTRACT_DETAILS_BY_DOC(
        serviceCPF,
        authStorage as string
      );

      switch (req?.status) {
        case 200:
          addClientInfo(req.result);

          //   setServiceCPF(req.result[0]?.cpf_cnpj);
          setServiceName(req.result[0]?.name);
          setServiceEmail(req.result[0]?.email);
          setServicePhone(req.result[0]?.mobile);
          break;
        case 401:
          toast.error("Sua sessão expirou. Faça login para continuar.", {
            autoClose: 4000,
          });

          setTimeout(() => {
            navigate("/");
          }, 3000);
          break;
      }
    }
  };

  const detailsRoute = (id: string | number) => {
    localStorage.setItem("_client_info_id", id?.toString());
    navigate("/dashboard/details-client");
  };

  const changeSeller = (event: any) => {
    setSelectedSeller(event.target.value);

    if (sellersList.length) {
      let sellerSelected = sellersList?.find(
        (seller: any) => seller?.id === Number(event.target.value)
      );

      if (sellerSelected) {
        setSellerPhone(sellerSelected.mobile);
      } else {
        setSellerPhone("");
      }
    }
  };

  const registerRoute = async (id: string) => {
    let infoReq = await CLIENTS_DETAILS_BY_ID(
      id as string,
      authStorage as string
    );

    switch (infoReq?.status) {
      case 200:
        addClientInfo(infoReq.result);

        navigate("/dashboard/add-client");
        break;
      case 401:
        toast.error("Sua sessão expirou. Faça login para continuar.", {
          autoClose: 4000,
        });

        setTimeout(() => {
          navigate("/");
        }, 3000);
        break;
    }
  };

  return (
    <div className="withdraw-FGTS">
      <AsideDefault />
      <ToolbarDefault />
      <main className="main-container">
        {isLoading || processStep === "" ? (
          <Loading />
        ) : (
          <>
            <DefaultNavBar title="SIMULAÇÃO SAQUE FGTS" />

            {processStep === "initial" && (
              <>
                <div className="personal-information">
                  <div className="text-container">
                    <h3>Novo Atendimento</h3>
                    {/* <p className="information-text warning-text"></p>
              <p className="information-text attention-text"></p>
              <p className="information-text attention-text"></p> */}
                  </div>

                  <div className="inputs-container">
                    <h4>INFORMAÇÕES PESSOAIS</h4>

                    <DefaultInput
                      label="CPF"
                      placeholder="123.456.789-10"
                      name="service-cpf"
                      onChange={serviceCPFHandler}
                      type="text"
                      value={serviceCPF}
                      //   onBlur={clientRegistrationDiscover}
                    />

                    <DefaultInput
                      label="Valor liberado (Se vazio, valor total)"
                      type="text"
                      name="releasedValue"
                      onChange={releasedValueHandler}
                      value={releasedValue}
                      placeholder="R$ 0,00"
                    />
                    {/* {isPromoter && (
                      <div className="sellers-list-info">
                        <DefaultSelect
                          htmlFor="sellers-list"
                          labelText="Nome do Vendedor"
                          options={sellersList?.map((seller) => {
                            return {
                              text: seller?.entity?.name,
                              value: seller.id,
                            };
                          })}
                          undefinedValueText="Escolha um vendedor"
                          name="sellers-list"
                          onChange={changeSeller}
                        />

                        <DefaultInput
                          label="Telefone do vendedor"
                          type="tel"
                          name="phoneSeller"
                          readOnly
                          value={sellerPhone}
                          className="phone-seller-select"
                        />
                      </div>
                    )} */}

                    <DefaultButton
                      onClick={() => consultSubmit("values")}
                      text="Consultar"
                      // isDisabled={!serviceCPF || serviceCPF.length < 14}
                      isSubmit={isLoading}
                    />
                  </div>
                </div>
              </>
            )}
            {processStep === "values" || processStep === "plans" ? (
              <>
                <h3 className="new-value-text">Valor liberado</h3>
                <div className="main-credit-condition">
                  <div className="new-value-input-button">
                    <DefaultInput
                      label="Novo valor (Se vazio, valor total)"
                      type="text"
                      name="releasedValue"
                      onChange={releasedValueHandler}
                      value={releasedValue}
                      placeholder="R$ 0,00"
                      className="new-value-input"
                    />
                    <button
                      className="new-value-button"
                      onClick={() => consultSubmit("values")}
                    >
                      Pesquisar
                    </button>
                  </div>
                  {Number(
                    //@ts-ignore
                    creditConditionsMoneyInsurence[0]?.ValorSolicitado
                  ) >= 250 ? (
                    <>
                      <div className="tables-show">
                        <h2>Tabela com seguro</h2>
                        <table className="condition-table">
                          <h3 className="title-table-category">Money +</h3>
                          <tbody>
                            <tr className="header-table-condition">
                              <th className="th-condition">TAXA</th>
                              <th className="th-condition">VALOR BRUTO</th>
                              <th className="th-condition">VALOR LÍQUIDO</th>
                              <th className="th-condition">TOTAL</th>
                            </tr>

                            {creditConditionsMoneyInsurence.map(
                              (condition: any, index: number) => (
                                <tr key={index}>
                                  <td className="td-condition">
                                    <label
                                      className="label-condition"
                                      htmlFor={index?.toString()}
                                    >
                                      <input
                                        className="input-condition"
                                        type="radio"
                                        name="input-condition"
                                        id={index?.toString()}
                                        onChange={() =>
                                          selectedCondition(condition)
                                        }
                                      />
                                      &nbsp; &nbsp;
                                      {condition.TaxaAPAM.toFixed(2)}%
                                    </label>
                                  </td>

                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorSolicitado?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorCliente?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td className="td-condition">
                                    {condition.ValorBruto?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        <table className="condition-table">
                          <h3 className="title-table-category">Laranja+</h3>
                          <tbody>
                            <tr className="header-table-condition">
                              <th className="th-condition">TAXA</th>
                              <th className="th-condition">VALOR BRUTO</th>
                              <th className="th-condition">VALOR LÍQUIDO</th>
                              <th className="th-condition">TOTAL</th>
                            </tr>

                            {creditConditionsLaranjaInsurence.map(
                              (condition: any, index: number) => (
                                <tr key={index}>
                                  <td className="td-condition">
                                    <label
                                      className="label-condition"
                                      htmlFor={index?.toString()}
                                    >
                                      <input
                                        className="input-condition"
                                        type="radio"
                                        name="input-condition"
                                        id={index?.toString()}
                                        onChange={() =>
                                          selectedCondition(condition)
                                        }
                                      />
                                      &nbsp; &nbsp;
                                      {condition.TaxaAPAM.toFixed(2)}%
                                    </label>
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorSolicitado?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorCliente?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td className="td-condition">
                                    {condition.ValorBruto?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        <table className="condition-table">
                          <h3 className="title-table-category">
                            {
                              //@ts-ignore
                              creditConditionsGold[0]?.ValorBruto >= 250 ? (
                                <>Gold+</>
                              ) : (
                                <>Smart</>
                              )
                            }
                          </h3>
                          <tbody>
                            <tr className="header-table-condition">
                              <th className="th-condition">TAXA</th>
                              <th className="th-condition">VALOR BRUTO</th>
                              <th className="th-condition">VALOR LÍQUIDO</th>
                              <th className="th-condition">TOTAL</th>
                            </tr>

                            {creditConditionsGoldInsurence.map(
                              (condition: any, index: number) => (
                                <tr key={index}>
                                  <td className="td-condition">
                                    <label
                                      className="label-condition"
                                      htmlFor={index?.toString()}
                                    >
                                      <input
                                        className="input-condition"
                                        type="radio"
                                        name="input-condition"
                                        id={index?.toString()}
                                        onChange={() =>
                                          selectedCondition(condition)
                                        }
                                      />
                                      &nbsp; &nbsp;
                                      {condition.TaxaAPAM.toFixed(2)}%
                                    </label>
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorSolicitado?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorCliente?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td className="td-condition">
                                    {condition.ValorBruto?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="tables-show">
                        <h2>Tabela sem seguro</h2>
                        <table className="condition-table">
                          <h3 className="title-table-category">Money</h3>
                          <tbody>
                            <tr className="header-table-condition">
                              <th className="th-condition">TAXA</th>
                              <th className="th-condition">VALOR BRUTO</th>
                              <th className="th-condition">VALOR LÍQUIDO</th>
                              <th className="th-condition">TOTAL</th>
                            </tr>

                            {creditConditionsMoney.map(
                              (condition: any, index: number) => (
                                <tr key={index}>
                                  <td className="td-condition">
                                    <label
                                      className="label-condition"
                                      htmlFor={index?.toString()}
                                    >
                                      <input
                                        className="input-condition"
                                        type="radio"
                                        name="input-condition"
                                        id={index?.toString()}
                                        onChange={() =>
                                          selectedCondition(condition)
                                        }
                                      />
                                      &nbsp; &nbsp;
                                      {condition.TaxaAPAM.toFixed(2)}%
                                    </label>
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorSolicitado?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorCliente?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td className="td-condition">
                                    {condition.ValorBruto?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        <table className="condition-table">
                          <h3 className="title-table-category">Laranja</h3>
                          <tbody>
                            <tr className="header-table-condition">
                              <th className="th-condition">TAXA</th>
                              <th className="th-condition">VALOR BRUTO</th>
                              <th className="th-condition">VALOR LÍQUIDO</th>
                              <th className="th-condition">TOTAL</th>
                            </tr>

                            {creditConditionsLaranja.map(
                              (condition: any, index: number) => (
                                <tr key={index}>
                                  <td className="td-condition">
                                    <label
                                      className="label-condition"
                                      htmlFor={index?.toString()}
                                    >
                                      <input
                                        className="input-condition"
                                        type="radio"
                                        name="input-condition"
                                        id={index?.toString()}
                                        onChange={() =>
                                          selectedCondition(condition)
                                        }
                                      />
                                      &nbsp; &nbsp;
                                      {condition.TaxaAPAM.toFixed(2)}%
                                    </label>
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorSolicitado?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorCliente?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td className="td-condition">
                                    {condition.ValorBruto?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                        <table className="condition-table">
                          <h3 className="title-table-category">
                            {
                              //@ts-ignore
                              creditConditionsGold[0]?.ValorBruto >= 250 ? (
                                <>Gold</>
                              ) : (
                                <>Smart</>
                              )
                            }
                          </h3>
                          <tbody>
                            <tr className="header-table-condition">
                              <th className="th-condition">TAXA</th>
                              <th className="th-condition">VALOR BRUTO</th>
                              <th className="th-condition">VALOR LÍQUIDO</th>
                              <th className="th-condition">TOTAL</th>
                            </tr>

                            {creditConditionsGold.map(
                              (condition: any, index: number) => (
                                <tr key={index}>
                                  <td className="td-condition">
                                    <label
                                      className="label-condition"
                                      htmlFor={index?.toString()}
                                    >
                                      <input
                                        className="input-condition"
                                        type="radio"
                                        name="input-condition"
                                        id={index?.toString()}
                                        onChange={() =>
                                          selectedCondition(condition)
                                        }
                                      />
                                      &nbsp; &nbsp;
                                      {condition.TaxaAPAM.toFixed(2)}%
                                    </label>
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorSolicitado?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorCliente?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td className="td-condition">
                                    {condition.ValorBruto?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="tables-show">
                        <h2>Tabela com seguro</h2>

                        <table className="condition-table">
                          <h3 className="title-table-category">Smart+</h3>
                          <tbody>
                            <tr className="header-table-condition">
                              <th className="th-condition">TAXA</th>
                              <th className="th-condition">VALOR BRUTO</th>
                              <th className="th-condition">VALOR LÍQUIDO</th>
                              <th className="th-condition">TOTAL</th>
                            </tr>

                            {creditConditionsSmartInsurence.map(
                              (condition: any, index: number) => (
                                <tr key={index}>
                                  <td className="td-condition">
                                    <label
                                      className="label-condition"
                                      htmlFor={index?.toString()}
                                    >
                                      <input
                                        className="input-condition"
                                        type="radio"
                                        name="input-condition"
                                        id={index?.toString()}
                                        onChange={() =>
                                          selectedCondition(condition)
                                        }
                                      />
                                      &nbsp; &nbsp;
                                      {condition.TaxaAPAM.toFixed(2)}%
                                    </label>
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorSolicitado?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorCliente?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td className="td-condition">
                                    {condition.ValorBruto?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>{" "}
                      <div className="tables-show">
                        <h2>Tabela sem seguro</h2>

                        <table className="condition-table">
                          <h3 className="title-table-category">Smart</h3>
                          <tbody>
                            <tr className="header-table-condition">
                              <th className="th-condition">TAXA</th>
                              <th className="th-condition">LIBERADO</th>
                              <th className="th-condition">TOTAL</th>
                            </tr>

                            {creditConditionsSmart.map(
                              (condition: any, index: number) => (
                                <tr key={index}>
                                  <td className="td-condition">
                                    <label
                                      className="label-condition"
                                      htmlFor={index?.toString()}
                                    >
                                      <input
                                        className="input-condition"
                                        type="radio"
                                        name="input-condition"
                                        id={index?.toString()}
                                        onChange={() =>
                                          selectedCondition(condition)
                                        }
                                      />
                                      &nbsp; &nbsp;
                                      {condition.TaxaAPAM.toFixed(2)}%
                                    </label>
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorSolicitado?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td
                                    className="td-condition"
                                    id={index?.toString()}
                                  >
                                    {condition.ValorCliente?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                  <td className="td-condition">
                                    {condition.ValorBruto?.toLocaleString(
                                      "pt-BR",
                                      {
                                        style: "currency",
                                        currency: "BRL",
                                      }
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              false
            )}
            {processStep === "plans" && (
              <>
                <div className="condition-payment-plan">
                  <p className="condition-payment-title">
                    PLANO DE PAGAMENTOS FGTS
                  </p>
                  <div>
                    {/* @ts-ignore */}
                    {conditionSelected?.Parcelas?.ListaParcela?.map(
                      (parcel: any, index: number) => (
                        <div key={index} className="plans-dates">
                          <p className="plans-dates-p">
                            {parcel.NumeroParcela} -
                            {formatDate(parcel.DataVencimento)}
                          </p>
                          <p className="plans-dates-p">
                            {parcel.ValorParcela?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </p>
                        </div>
                      )
                    )}
                    <div className="plans-dates">
                      <p className="plans-dates-p">TOTAL</p>
                      <p className="plans-dates-p">
                        {/* @ts-ignore */}
                        {conditionSelected.ValorBruto?.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </p>
                    </div>
                  </div>
                  <div className="plain-details">
                    <div className="total-parcel-text">
                      <div className="center">
                        <p className="total-parcel-text-principal">Parcelas</p>
                        <p className="total-parcel-text-value">
                          {/* @ts-ignore */}
                          {conditionSelected.QuantidadeParcelas}
                        </p>
                      </div>
                      <div className="center">
                        <p className="total-parcel-text-principal">
                          Valor Liberado
                        </p>
                        <p className="total-parcel-text-value">
                          {/* @ts-ignore */}
                          {conditionSelected.ValorCliente?.toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )}
                        </p>
                      </div>
                    </div>

                    <div className="data-info-details">
                      <div className="details-info">
                        <p className="details-info-p">TC</p>
                        <p className="details-info-p">
                          {/* @ts-ignore */}
                          {conditionSelected?.Despesas?.ListaDespesa[0]?.Valor?.toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )}
                        </p>
                      </div>
                      <div className="details-info">
                        <p className="details-info-p">IOF</p>
                        <p className="details-info-p">
                          {/* @ts-ignore */}
                          {conditionSelected.ValorIOF?.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          })}
                        </p>
                      </div>
                      <div className="details-info">
                        <p className="details-info-p">Tabela</p>
                        <p className="details-info-p">
                          {/* @ts-ignore */}
                          {conditionSelected?.TaxaAPAM?.toFixed(2)}% a.m /
                          {/* @ts-ignore */}
                          {conditionSelected?.TaxaAPAA?.toFixed(2)}% a.a.
                        </p>
                      </div>
                      <div className="details-info">
                        <p className="details-info-p">CET</p>
                        <p className="details-info-p">
                          {/* @ts-ignore */}
                          {conditionSelected.TaxaCETAM?.toFixed(2)}% a.m. /
                          {/* @ts-ignore */}
                          {conditionSelected.TaxaCETAA?.toFixed(2)}% a.a.
                        </p>
                      </div>
                      <div className="details-info">
                        <p className="details-info-p">Comissão</p>
                        <p className="details-info-p">R$ 0,00(0%)</p>
                      </div>
                      <div className="details-info">
                        <p className="details-info-p">Saldo Retido</p>
                        <p className="details-info-p">
                          {/* @ts-ignore */}
                          {conditionSelected.ValorBruto?.toLocaleString(
                            "pt-BR",
                            {
                              style: "currency",
                              currency: "BRL",
                            }
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <DefaultButton
                    text="Continuar"
                    onClick={() => setProcessStep("complete-info")}
                    isSubmit={isLoading}
                  />
                </div>
              </>
            )}
            {processStep === "complete-info" && (
              <div className="personal-information">
                <div className="inputs-container">
                  <h4>Complete as Informações</h4>
                  <DefaultInput
                    label="Nome"
                    placeholder="Fulano da Silva"
                    name="service-name"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setServiceName(e.target.value)
                    }
                    type="text"
                    value={serviceName}
                  />
                  <DefaultInput
                    label="Email"
                    placeholder="exemplo@emial.com"
                    name="service-email"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setServiceEmail(e.target.value)
                    }
                    type="email"
                    value={serviceEmail}
                  />
                  <DefaultInput
                    label="Telefone"
                    placeholder="(99) 9 9999-9999"
                    name="service-phone"
                    onChange={servicePhoneHandler}
                    type="tel"
                    value={servicePhone}
                  />
                  <DefaultButton
                    onClick={createFGTS}
                    text="Continuar"
                    isDisabled={!serviceName || !serviceEmail || !servicePhone}
                    isSubmit={isLoading}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </main>
    </div>
  );
};

export default WithdrawFGTSPage;
