import VMasker from "vanilla-masker";

export const BRLFormat = (/** @type {number | string} */ value) => {
	return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}

export const formatDate = (/** @type {string | number | Date} */ date) => {
	date = new Date(date);
	let day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
	let month =
		date.getMonth() + 1 < 10
			? `0${date.getMonth() + 1}`
			: date.getMonth() + 1;
	return `${day}/${month}/${date.getFullYear()}`;
};


export const formatHour = (/** @type {string | number | Date} */ date) => {
	date = new Date(date)
	const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
	const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
	const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
	return `${hour}:${minutes}:${seconds}`
}


export const formatUserDocument = (/**@type {string | undefined} */doc) => {
	if (doc) {
		doc = doc.replace(/\D/g, "")?.trim()
		return doc.length < 11 ? doc : doc.length === 11 ? VMasker.toPattern(doc, "999.999.999-99") : VMasker.toPattern(doc, "99.999.999/9999-99")
	} else {
		return doc
	}

}

export const formatCellPhone = (/** @type {string | undefined} */phone) => {
	if (phone) {
		return VMasker.toPattern(phone, "(99) 9 9999-9999")
	} else {
		return phone
	}
}