import { AxiosError } from "axios";
import { REQUEST } from "../request";

const BALANCE_FGTS = async (
  cpf: string,
  value_free: number | null,
  token: string,
  seller_id?: number
) => {
  try {
    const req = await REQUEST({
      endPoint: "/simulations/balance_fgts",
      urlBase: "novoSaque",
      params: seller_id,
      method: "post",
      token,
      data: {
        cpf,
        value_free,
        birth_date: "1980-05-20", //valor fixo
      },
    });

    return {
      creditCondition: req.data.CondicoesCredito.ListaCondicaoCredito,
      res: req.data,
      status: req.status,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default BALANCE_FGTS;
