import { MouseEventHandler } from "react";
import "./style.css";
import Loading from "../../../assets/loading-icon.svg";

type DefaultButtonProps = {
  text: string | number | undefined;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  className?: string;
  isDisabled?: boolean;
  hidden?: boolean;
  isSubmit?: boolean;
};

const DefaultButton = (props: DefaultButtonProps) => {
  const { onClick, text, className, isDisabled, hidden, isSubmit } = props;
  return (
    <button
      id="default-button"
      className={className}
      onClick={onClick}
      disabled={isDisabled || isSubmit}
      hidden={hidden}
      style={{
        cursor: `${isDisabled || isSubmit ? "not-allowed" : "pointer"}`,
      }}
    >
      {isSubmit ? (
        <img src={Loading} className="loading-button" alt="loading icon" />
      ) : (
        text
      )}
    </button>
  );
};

export default DefaultButton;
