import { AxiosError } from "axios";
import { REQUEST } from "../request";

const ACTIVE_REACTIVE = async (
  seller_id: number,
  action: string,
  token: string
) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: `/sellers/${seller_id}`,
      method: "put",
      token,
      data: { seller: { status: action } },
    });

    return { result: req.data, status: req.status };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default ACTIVE_REACTIVE;
