import { createContext, useState } from "react";

export const SellerInfoContext = createContext()

export const SellerProvider = ({ children }) => {
	const [sellerInfo, setSellerInfo] = useState(null)

	const addSellerInfo = (info) => {
		setSellerInfo(info);
	};

	const clearSellerInfo = () => {
		setSellerInfo(null);
	};

	return (
		<SellerInfoContext.Provider value={{ sellerInfo, addSellerInfo, clearSellerInfo }}>
			{children}
		</SellerInfoContext.Provider>
	)
}