import { useNavigate } from "react-router-dom";
import "./style.css";
import AsideDefault from "../../../components/AsideDefault";
import ToolbarDefault from "../../../components/Toolbars/ToolbarDefault";
import DefaultNavBar from "../../../components/Navs/DefaultNavBar";
import Loading from "../../../components/Loading";
import { useEffect, useState } from "react";
import GET_PRE_REGISTERS from "../../../utils/apis/getPreRegisters";
import { PreRegister } from "../../../types/preRegsiterInfo";
import { formatDate } from "../../../utils/formatDate";
import {
  formatCellPhone,
  formatUserDocument,
} from "../../../utils/formatString";

const PreRegisteredList = () => {
  const [showRegisterDetails, setShowRegisterDetails] = useState(false);
  const [preRegisters, setPreRegisters] = useState<PreRegister[] | []>();
  const [selectedPreRegister, setSelectedPreRegister] = useState<PreRegister>();

  useEffect(() => {
    setShowRegisterDetails(false);
    const preRegisterList = async () => {
      let req = await GET_PRE_REGISTERS();
      setPreRegisters(req?.result);
    };

    preRegisterList();
  }, []);

  const registerDetails = (reg: PreRegister) => {
    setShowRegisterDetails(true);
    setSelectedPreRegister(reg);
  };

  console.log(selectedPreRegister);

  const attendantType = (AttType: number | undefined) => {
    switch (AttType) {
      case 0:
        return "Presencial";
      case 1:
        return "Online";
      case 2:
        return "Telefônico (Call Center)";
      default:
        return "Não definido";
    }
  };

  const pixType = (pix: number | string | undefined) => {
    switch (Number(pix)) {
      case 0:
        return "CPF";
      case 1:
        return "CNPJ";
      case 2:
        return "Email";
      case 3:
        return "Telefone";
      case 4:
        return "Chave Aleatória";
      default:
        return "Não definido";
    }
  };

  return (
    <div className="seller-list">
      <AsideDefault />
      <ToolbarDefault />
      <main className="main-container">
        <DefaultNavBar title="Vendedores" />
        {/* {isLoading || !listSeller.length ? <Loading /> : <></>} */}
        {!showRegisterDetails ? (
          <div className="tables-show">
            <table className="condition-table">
              <tbody>
                <tr className="header-table-condition">
                  <th className="th-condition">NOME</th>
                  <th className="th-condition">EMAIL</th>
                  <th className="th-condition">CELULAR</th>
                  <th className="th-condition">DATA DO CADASTRO</th>
                  <th className="th-condition"></th>
                </tr>
                {preRegisters?.map((reg, index) => (
                  <tr key={index}>
                    <td className="td-condition">{reg.nome}</td>
                    <td className="td-condition">{reg.email}</td>
                    <td className="td-condition">{reg.phone}</td>
                    <td className="td-condition">
                      {formatDate(reg.createdAt)}
                    </td>

                    <button
                      className="details-pre-register-button"
                      onClick={() => registerDetails(reg)}
                    >
                      Detalhes
                    </button>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="details-pre-register">
            <button
              className="details-pre-register-button-list"
              onClick={() => setShowRegisterDetails(false)}
            >
              Lista completa
            </button>
            <p className="sub-title-pre-details">Dados pessoais</p>
            <div className="sales-info">
              <div className="detail">
                <p className="detail-text">Nome</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.nome}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Atendimento</p>
                <p className="detail-text sub-text">
                  {attendantType(selectedPreRegister?.atendimento)}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Telefone</p>
                <p className="detail-text sub-text">
                  {formatCellPhone(selectedPreRegister?.phone)}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Email</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.email}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Criado em </p>
                <p className="detail-text sub-text">
                  {formatDate(selectedPreRegister?.createdAt)}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">CPF</p>
                <p className="detail-text sub-text">
                  {formatUserDocument(selectedPreRegister?.document)}
                </p>
              </div>
            </div>
            <p className="sub-title-pre-details">Endereço</p>
            <div className="sales-info">
              <div className="detail">
                <p className="detail-text">CEP</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.endereco.cep}
                </p>
              </div>{" "}
              <div className="detail">
                <p className="detail-text">Cidade</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.endereco.cidade}
                </p>
              </div>{" "}
              <div className="detail">
                <p className="detail-text">Estado</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.endereco.estado}
                </p>
              </div>{" "}
              <div className="detail">
                <p className="detail-text">Bairro</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.endereco.bairro}
                </p>
              </div>{" "}
              <div className="detail">
                <p className="detail-text">Rua</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.endereco.rua}
                </p>
              </div>{" "}
              <div className="detail">
                <p className="detail-text">Número</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.endereco.numero}
                </p>
              </div>{" "}
              <div className="detail">
                <p className="detail-text">Complemento</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.endereco.complemento}
                </p>
              </div>
            </div>
            <p className="sub-title-pre-details">Informações bancárias</p>

            <div className="sales-info">
              <div className="detail">
                <p className="detail-text">Nome do banco</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.dadosBancarios.banco}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Agência</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.dadosBancarios.agencia}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Conta</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.dadosBancarios.conta}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Dígito</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.dadosBancarios.digito}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Tipo de Conta</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.dadosBancarios.tipoConta === 0
                    ? "Conta corrente"
                    : "Conta poupança"}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Tipo de Pix</p>
                <p className="detail-text sub-text">
                  {pixType(selectedPreRegister?.dadosBancarios.tipoPix)}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Chave Pix</p>
                <p className="detail-text sub-text">
                  {selectedPreRegister?.dadosBancarios.chavePix}
                </p>
              </div>
            </div>
            {selectedPreRegister?.tipoLoja === 1 && (
              <>
                <p className="sub-title-pre-details">Representante Legal</p>

                <div className="sales-info">
                  <div className="detail">
                    <p className="detail-text">Nome do representante</p>
                    <p className="detail-text sub-text">
                      {selectedPreRegister?.representanteLegal.nome}
                    </p>
                  </div>{" "}
                  <div className="detail">
                    <p className="detail-text">Telefone</p>
                    <p className="detail-text sub-text">
                      {selectedPreRegister?.representanteLegal.celular}
                    </p>
                  </div>{" "}
                  <div className="detail">
                    <p className="detail-text">Email</p>
                    <p className="detail-text sub-text">
                      {selectedPreRegister?.representanteLegal.email}
                    </p>
                  </div>{" "}
                  <div className="detail">
                    <p className="detail-text">Documento</p>
                    <p className="detail-text sub-text">
                      {selectedPreRegister?.representanteLegal.document}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </main>
    </div>
  );
};

export default PreRegisteredList;
