import { createContext, useState } from "react";

export const SimulationContext = createContext()

export const SimulationProvider = ({ children }) => {
	const [simulationInfo, setSimulationInfo] = useState(null)

	const addSimulationInfo = (info) => {
		setSimulationInfo(info)
	}

	const removeSimulationInfo = () => {
		setSimulationInfo(null)
	}

	return (
		<SimulationContext.Provider value={{ simulationInfo, addSimulationInfo, removeSimulationInfo }}>
			{children}
		</SimulationContext.Provider>
	)

}