import { useEffect, useState } from "react";
import "./style.css";
import GET_ENTITY from "../../../utils/apis/getEntity";
import { useNavigate } from "react-router-dom";
import AsideDefault from "../../../components/AsideDefault";
import ToolbarDefault from "../../../components/Toolbars/ToolbarDefault";
import DefaultNavBar from "../../../components/Navs/DefaultNavBar";
import DefaultInput from "../../../components/Inputs/DefaultInput";
import { CommissionCardInfo } from "../../../types/commissionCardInfo";

const CommissionCardDataPage = () => {
  const navigate = useNavigate();
  let authStorage = sessionStorage.getItem("@_auth");
  const [isLoading, setIsLoading] = useState(false);
  const [commissionList, setCommissionList] = useState<
    CommissionCardInfo[] | []
  >([]);

  useEffect(() => {
    if (authStorage === "null" || authStorage === null) {
      localStorage.clear();
      sessionStorage.clear();
      setIsLoading(false);
      return navigate("/");
    }

    const commissionList = async () => {
      let req = await GET_ENTITY(authStorage);

      setCommissionList(req?.result.commission_bases_report);
    };

    commissionList();
    setIsLoading(false);
  }, []);

  return (
    <div className="commission-card-data">
      <AsideDefault />
      <ToolbarDefault />
      <main className="main-container commission-main">
        <DefaultNavBar title="Dados de Comissão Cartão" />
        {commissionList?.map((commission, index) => (
          <>
            <div className="commission-table" key={index}>
              <div className="grid-col2-commission">
                <DefaultInput
                  readOnly
                  type="text"
                  value={commission?.basic_interest_name}
                  label="Cod. Tabela"
                />
                <DefaultInput
                  readOnly
                  type="text"
                  label="Juros Base"
                  value={`${commission.basic_interest.toFixed(2)}%`}
                />
              </div>
              {commission.installments.map((installment, index) => (
                <div className="grid-col3 " key={index}>
                  <DefaultInput
                    type="number"
                    value={installment.installment}
                    label="Parcelas"
                  />
                  <DefaultInput
                    type="text"
                    value={`${installment.value.toFixed(2)}%`}
                    label="Comissão"
                  />
                  <DefaultInput type="text" value={""} label="Coeficiente" />
                </div>
              ))}
            </div>
            <hr />
          </>
        ))}
      </main>
    </div>
  );
};

export default CommissionCardDataPage;
