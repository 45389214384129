import { useContext, useEffect, useState } from "react";
import "./style.css";
import { AuthContext } from "../../../contexts/userContext";
import { useNavigate } from "react-router-dom";
import ContractIcon from "../../../assets/contract-icon.svg";
import SimulationContractIcon from "../../../assets/contract-simulation-icon.svg";
import logoutIcon from "../../../assets/logout.svg";
import searchContract from "../../../assets/filse-search.svg";
import ManagerIcon from "../../../assets/manager-icon.svg";

type ToolbarDefaultProps = {};

const ToolbarDefault = (props: ToolbarDefaultProps) => {
  const { logout: logoutContext } = useContext(AuthContext);

  const navigate = useNavigate();

  let store = sessionStorage.getItem("@store");

  const linksToolbar = [
    {
      id: 1,
      onClick: () => navigate("/withdraw-fgts"),
      icon: SimulationContractIcon,
      altIcon: "simulation contract icon",
      textLink: "Simulação saque FGTS",
    },
    {
      id: 2,
      onClick: () => navigate("/search-contract"),
      icon: ContractIcon,
      altIcon: "contract icon",
      textLink: "Contratos FGTS",
    },
    {
      id: 3,
      onClick: () => navigate("/proposal-status"),
      icon: searchContract,
      altIcon: "search contract icon",
      textLink: "Acompanhar Propostas",
    },
    {
      id: 4,
      onClick: () => navigate("/manager-area"),
      icon: ManagerIcon,
      altIcon: "search contract icon",
      textLink: "Área do gerente",
    },
    {
      id: 5,
      onClick: () => logout(),
      icon: logoutIcon,
      altIcon: "log out icon",
      textLink: "Sair",
    },
  ];

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    logoutContext();
    navigate("/");
  };
  let buttons;
  if (!store || store === null || store === "null") {
    buttons = linksToolbar.filter((link) => link.id !== 4);
  } else if (store) {
    buttons = linksToolbar.filter((link) => link.id !== 1);
  } else {
    buttons = linksToolbar;
  }

  return (
    <div className="toolbar-default">
      <div className="links-container-toolbar">
        {buttons.map((link, index) => (
          <button key={index} className="link-toolbar" onClick={link.onClick}>
            <img
              className="image-link-toolbar"
              src={link.icon}
              alt={link.altIcon}
            />
            <p className="text-link">{link.textLink}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ToolbarDefault;
