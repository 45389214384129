import { createContext, useState } from "react";

export const CostumerContext = createContext()

export const CostumerInfoProvider = ({ children }) => {
	const [costumerInfo, setCostumerInfo] = useState(null)

	const addCostumerInfo = (info) => {
		setCostumerInfo(info)
	}

	const removeCostumerInfo = () => {
		setCostumerInfo(null)
	}

	return (
		<CostumerContext.Provider value={{ costumerInfo, addCostumerInfo, removeCostumerInfo }}>
			{children}
		</CostumerContext.Provider>
	)

}