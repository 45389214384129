import { useState } from "react";
import AsideDefault from "../../../components/AsideDefault";
import DefaultInput from "../../../components/Inputs/DefaultInput";
import DefaultNavBar from "../../../components/Navs/DefaultNavBar";
import ToolbarDefault from "../../../components/Toolbars/ToolbarDefault";
import "./style.css";
import { Property } from "../../../types/property";
import { GET_CEP } from "../../../utils/apis/getCEP";
import { CEPMask } from "../../../utils/masks";
import DefaultSelect from "../../../components/Selects/DefaultSelect";
import DefaultButton from "../../../components/Buttons/DefaultButton";
import REGISTER_SELLER from "../../../utils/apis/registerSeller";
import VMasker from "vanilla-masker";
import CHECK_DOC_EXIST from "../../../utils/apis/checkCPFSellerExist";
import { toast } from "react-toastify";
import CHECK_EMAIL_EXIST from "../../../utils/apis/checkEmailSellerExist";
import { useNavigate } from "react-router-dom";

const SellerRegisterPage = () => {
  let authStorage = sessionStorage.getItem("@_auth");
  const navigate = useNavigate();

  const [CEPNumber, setCEPNumber] = useState("");
  const [sellerAddress, setSellerAddress] = useState<Property>({
    zipcode: "",
    state: "",
    neighbourhood: "",
    city: "",
    address: "",
    number: "",
    complement: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [kindPix, setKindPix] = useState("");
  const [pix, setPix] = useState("");
  const [storeId, setStoreId] = useState(null);
  const [mobile, setMobile] = useState("");
  const [name, setName] = useState("");
  const [document, setDocument] = useState("");
  const [hasCommission, setHasCommission] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [commissionType, setCommissionType] = useState(1);
  const [valueCommission, setValueCommission] = useState("");
  const [trigger, setTrigger] = useState("");
  const [baseTrigger, setBaseTrigger] = useState("");

  const maskCEP = async (event: React.ChangeEvent<HTMLInputElement>) => {
    CEPMask(event);
    let rawCep = event.target.value;

    setCEPNumber(rawCep);
    if (rawCep.length >= 8) {
      let res = await GET_CEP(rawCep);

      setSellerAddress({
        // @ts-ignore
        zipcode: res.cep,
        // @ts-ignore
        address: res.logradouro,
        // @ts-ignore
        city: res.localidade,
        // @ts-ignore
        complement: "",
        // @ts-ignore
        neighbourhood: res.bairro,
        number: "",
        // @ts-ignore
        state: res.uf,
      });
    }
  };

  const serviceCPFHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    VMasker(e.target).unMask();
    VMasker(e.target).maskPattern("999.999.999-99");
    setDocument(e.target.value);
  };

  const servicePhoneHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    VMasker(e.target).unMask();
    VMasker(e.target).maskPattern("(99) 9 9999-9999");
    setMobile(e.target.value);
  };

  const documentExist = async () => {
    let req = await CHECK_DOC_EXIST(
      document.replace(/\D/g, ""),
      authStorage as string
    );

    if (req?.result.message === "CPF já consta no sistema") {
      toast.error(req?.result.message);

      setTimeout(() => {
        setDocument("");
      }, 2000);
    }
  };

  const emailExist = async () => {
    let req = await CHECK_EMAIL_EXIST(email, authStorage as string);
    if (req?.result.message === "E-mail já consta no sistema") {
      toast.error(req?.result.message);

      setTimeout(() => {
        setEmail("");
      }, 2000);
    }
  };

  const registerSeller = async () => {
    if (
      !CEPNumber ||
      !sellerAddress.address ||
      !sellerAddress.number ||
      !sellerAddress.neighbourhood ||
      !sellerAddress.city ||
      !sellerAddress.state ||
      !kindPix ||
      !pix ||
      !name ||
      !document ||
      !email ||
      !mobile ||
      !password ||
      !CEPNumber
    ) {
      let emptyField: string | null = null;

      if (!CEPNumber) emptyField = "cep";
      if (!pix) emptyField = "pix";
      if (!sellerAddress.address) emptyField = "Endereço";
      if (!kindPix) emptyField = "Tipo do pix";
      if (!sellerAddress.city) emptyField = "Cidade";
      if (!sellerAddress.state) emptyField = "Estado";
      if (!sellerAddress.neighbourhood) emptyField = "Bairro";
      if (!sellerAddress.number) emptyField = "Número";
      if (!password) emptyField = "senha";
      if (!mobile) emptyField = "celular";
      if (!email) emptyField = "email";
      if (!document) emptyField = "cpf";
      if (!name) emptyField = "nome";

      setIsLoading(false);
      return toast.warning(
        `O campo "${emptyField?.toUpperCase()}" não foi preenchido`
      );
    }

    setIsLoading(true);
    const registerObj = {
      seller: {
        kind_pix: kindPix,
        pix: pix,
        store_id: storeId,
        mobile: mobile,
        entity_attributes: {
          name: name,
          cpf_cnpj: document,
          has_commission: hasCommission,
          address_attributes: {
            zip_code: sellerAddress.zipcode,
            street: sellerAddress.address,
            number: sellerAddress.number,
            district: sellerAddress.neighbourhood,
            city: sellerAddress.city,
            state: sellerAddress.state,
            complement: sellerAddress.complement,
          },
          commission_bases_attributes: [
            {
              commission_type: commissionType,
              value: valueCommission,
              trigger: trigger,
              base_trigger: baseTrigger,
            },
          ],
        },
        users_attributes: [
          {
            cpf: document,
            name: name,
            mobile: mobile,
            email: email,
            password: password,
          },
        ],
      },
    };

    let req = await REGISTER_SELLER(registerObj, authStorage as string);
    switch (req?.status) {
      case 200:
        toast.success("Vendedor criado com sucesso!");
        setTimeout(() => {
          navigate("/manager-area/seller-list");
        }, 1000);

        break;
      default:
        toast.error("Erro! Verifique todos os campos e tente novamente.");

        break;
    }
  };

  return (
    <div className="seller-register">
      <AsideDefault />
      <ToolbarDefault />
      <main className="main-container">
        <DefaultNavBar title="Registrar novo vendedor" />{" "}
        <div className="main-container-inputs-seller">
          <div className="input-container">
            <h4 className="sub-text">INFORMAÇÕES DO VENDEDOR</h4>
            <div className="grid-cols2-seller padding-bottom">
              <DefaultInput
                label="Nome do vendedor"
                type="text"
                value={name}
                placeholder="Fulano da Silva"
                onChange={(e) => setName(e.target.value)}
              />
              <DefaultInput
                label="CPF"
                type="text"
                value={document}
                onChange={serviceCPFHandler}
                onBlur={documentExist}
                placeholder="999.999.999-99"
              />
            </div>
          </div>
          <div className="input-container">
            <h4 className="sub-text">CONTATO DO VENDEDOR</h4>
            <div className="grid-cols2-seller padding-bottom">
              <DefaultInput
                label="Email (Login de acesso)"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="fulano@email.com"
                onBlur={emailExist}
              />
              <DefaultInput
                label="Celular"
                type="tel"
                value={mobile}
                onChange={servicePhoneHandler}
                placeholder="(11) 9 9999-9999"
              />
              <DefaultInput
                label="Senha"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="input-container">
            <h4 className="sub-text">ENDEREÇO DO VENDEDOR</h4>
            <div className="grid-cols2-seller padding-bottom">
              <DefaultInput
                name="CEP"
                onChange={maskCEP}
                type="text"
                value={CEPNumber}
                label="CEP"
                placeholder="00000-000"
              />
              <DefaultInput
                label="Endereço"
                type="text"
                value={sellerAddress.address}
                onChange={(e) =>
                  setSellerAddress({
                    ...sellerAddress,
                    address: e.target.value,
                  })
                }
                placeholder="Rua"
              />
              <DefaultInput
                label="Número"
                type="tel"
                value={sellerAddress.number}
                onChange={(e) =>
                  setSellerAddress({
                    ...sellerAddress,
                    number: e.target.value,
                  })
                }
                placeholder="100"
              />
              <DefaultInput
                label="Bairro"
                type="text"
                value={sellerAddress.neighbourhood}
                onChange={(e) =>
                  setSellerAddress({
                    ...sellerAddress,
                    neighbourhood: e.target.value,
                  })
                }
                placeholder="Bairro"
              />
              <DefaultInput
                label="Cidade"
                type="text"
                value={sellerAddress.city}
                onChange={(e) =>
                  setSellerAddress({
                    ...sellerAddress,
                    city: e.target.value,
                  })
                }
                placeholder="Cidade"
              />
              <DefaultInput
                label="Estado"
                type="text"
                value={sellerAddress.state}
                onChange={(e) =>
                  setSellerAddress({
                    ...sellerAddress,
                    state: e.target.value,
                  })
                }
                placeholder="Estado"
              />
              <DefaultInput
                label="Complemento"
                type="text"
                value={sellerAddress.complement}
                onChange={(e) =>
                  setSellerAddress({
                    ...sellerAddress,
                    complement: e.target.value,
                  })
                }
                placeholder="Ex.: Casa b"
              />
            </div>
          </div>
          <div className="input-container">
            <h4 className="sub-text">CADASTRO DO PIX</h4>
            <div className="grid-cols2-seller padding-bottom">
              <DefaultSelect
                labelText="CADASTRO DO PIX"
                undefinedValueText="Selecione o tipo"
                htmlFor="pix-type-select"
                onChange={(e) => setKindPix(e.target.value)}
                options={[
                  { text: "CPF/CNPJ", value: "cpf_cnpj" },
                  { text: "Email", value: "email" },
                  { text: "Telefone", value: "phone" },
                ]}
              />
              <DefaultInput
                label="Pix"
                type="text"
                value={pix}
                onChange={(e) => setPix(e.target.value)}
              />
            </div>
          </div>
          {/* <div className="input-container">
            <h4 className="sub-text">CADASTRO DE COMISSÃO</h4>
            <div className="grid-cols2-seller padding-bottom">
              <DefaultSelect
                labelText="Existe uma comissão?"
                undefinedValueText="Selecione o tipo"
                htmlFor="commission-type-select"
                onChange={(e) => setHasCommission(!!e.target.value)}
                options={[
                  { text: "Sim", value: 1 },
                  { text: "Não", value: 0 },
                ]}
              />
            </div>
          </div> */}
        </div>
        <DefaultButton
          onClick={registerSeller}
          text="Registrar vendedor"
          isSubmit={isLoading}
        />
      </main>
    </div>
  );
};

export default SellerRegisterPage;
