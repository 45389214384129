import axios from "axios";
import URLS from "./service-api";

type RequestProps = {
  endPoint: string;
  data?: object;
  token?: string;
  params?: any;
  method: "get" | "post" | "put" | "delete";
  urlBase: "nyc" | "novoSaque";
};

export const REQUEST = async (props: RequestProps) => {
  const { params, method, data, token, endPoint, urlBase } = props;

  let baseURL =
    urlBase === "nyc" ? URLS.NYC_FGTS_BASE_URL : URLS.NOVO_SAQUE_BASE_URL;

  let req = await axios({
    params,
    method,
    url: `${baseURL}${endPoint}`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return req;
};
