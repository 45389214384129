import { AxiosError } from "axios";
import { REQUEST } from "../request";

const CONTRACT_DETAILS_BY_DOC = async (doc_number: string, token: string) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: `/contracts/get_customer_by_cpf?q[entity_cpf_cnpj_eq]=${doc_number.replace(
        /\D/g,
        ""
      )}`,
      method: "get",
      token,
    });

    return { result: req.data, status: req.status };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default CONTRACT_DETAILS_BY_DOC;
