import { AxiosError } from "axios";
import { REQUEST } from "../request";

const LOGIN = async (email: string, password: string) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: "/login",
      method: "post",
      data: {
        email,
        password,
      },
    });

    return { result: req.data, status: req.status };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default LOGIN;
