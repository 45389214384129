import { useEffect, useState } from "react";
import AsideDefault from "../../../components/AsideDefault";
import DefaultNavBar from "../../../components/Navs/DefaultNavBar";
import ToolbarDefault from "../../../components/Toolbars/ToolbarDefault";
import "./style.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ALL_SELLERS from "../../../utils/apis/getAllSellers";
import ACTIVE_REACTIVE from "../../../utils/apis/activeReactiveSeller";
import Loading from "../../../components/Loading";

const SellersListPage = () => {
  let authStorage = sessionStorage.getItem("@_auth");
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const [listSeller, setListSeller] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (authStorage === "null" || authStorage === null) {
      localStorage.clear();
      sessionStorage.clear();
      setIsLoading(false);
      return navigate("/");
    }

    let listSellers = async () => {
      let req = await ALL_SELLERS(page, perPage, authStorage as string);

      setListSeller(req?.result);
    };
    listSellers();
    setIsLoading(false);
  }, []);

  const disableEnableSeller = async (id: number, action: any) => {
    let actionReq = action === "active" ? "disabled" : "active";
    let req = await ACTIVE_REACTIVE(id, actionReq, authStorage as string);

    switch (req?.status) {
      case 200:
        toast.success(
          `Cliente ${
            action !== "active" ? "ativado" : "desativado"
          } com sucesso!`
        );

        setTimeout(() => {
          window.location.reload();
        }, 1200);

        break;
    }
  };

  return (
    <div className="seller-list">
      <AsideDefault />
      <ToolbarDefault />
      <main className="main-container">
        <DefaultNavBar title="Vendedores" />
        {isLoading || !listSeller.length ? (
          <Loading />
        ) : (
          <div className="table-info">
            <table className="main-table">
              <tbody className="main-table-body">
                <tr className="header-table">
                  <th className="th">CPF</th>
                  <th className="th">NOME</th>
                  <th className="th">TELEFONE</th>
                  <th className="th">STATUS</th>
                  <th className="th">ATIVAR/DESATIVAR</th>
                </tr>

                {listSeller.map((seller: any, index: number) => (
                  <tr className="tr seller-tr" key={index}>
                    <td className="td seller-td">{seller.entity.cpf_cnpj}</td>
                    <td className="td seller-td">{seller.entity.name}</td>
                    <td className="td seller-td">{seller.mobile}</td>
                    <td className={`td seller-td ${seller.status_original}`}>
                      {seller.status}
                    </td>
                    <td className="td seller-td">
                      <button
                        className={`menu-icon-button seller-button back-ground-${seller.status_original}`}
                        onClick={() =>
                          disableEnableSeller(
                            seller.id,
                            //@ts-ignore
                            `${seller.status_original}`
                          )
                        }
                      >
                        {seller.status === "Ativo" ? "Desativar" : "Ativar"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </main>
    </div>
  );
};
export default SellersListPage;
