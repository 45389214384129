import { AxiosError } from "axios";
import { CollaboratorInfo } from "../../types/collaboratorInfo";
import { REQUEST } from "../request";

const REGISTER_COLLABORATOR = async (data: CollaboratorInfo) => {
  try {
    const req = await REQUEST({
      urlBase: "nyc",
      endPoint: `/PreRegister/insertPreRegister`,
      method: "post",

      data,
    });

    return {
      result: req.data.simulation,
      status: req.status,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default REGISTER_COLLABORATOR;
