import { AxiosError } from "axios";
import { REQUEST } from "../request";

const COMMISSIONS_FGTS = async (
  data: { rate: string; tac: number; value: number },
  token: string
) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: "/simulations/commissions_fgts?",
      method: "post",
      token,
      data,
    });

    return {
      result: req.data.simulation,
      status: req.status,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default COMMISSIONS_FGTS;
