import React, { useContext, useEffect, useState } from "react";
import DefaultInput from "../../components/Inputs/DefaultInput";
import "./style.css";
import DefaultSelect from "../../components/Selects/DefaultSelect";
import { banks } from "../../mocks/banksMock";
import DefaultButton from "../../components/Buttons/DefaultButton";
import { GET_CEP } from "../../utils/apis/getCEP";
import { CollaboratorInfo } from "../../types/collaboratorInfo";
import { Link, useNavigate } from "react-router-dom";
import REGISTER_COLLABORATOR from "../../utils/apis/registerCollaborator";
import { AuthContext } from "../../contexts/userContext";
import { ToastContainer, toast } from "react-toastify";
import { formatCellPhone, formatUserDocument } from "../../utils/formatString";

const CollaboratorPreRegistrationPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [storeInfo, setStoreInfo] = useState({
    storeType: "",
    typeService: "",
    corporateReason: "",
    fantasyName: "",
    document: "",
  });

  const [colContact, seColContact] = useState({
    phone: "",
    email: "",
  });

  const [addressToRegister, setAddressToRegister] = useState({
    regStreet: "",
    regZipCode: "",
    regNumber: "",
    regComplement: "",
    regCity: "",
    regNeighborhood: "",
    regState: "",
  });

  const [representativeInfo, setRepresentativeInfo] = useState({
    repCPF: "",
    repName: "",
    repEmail: "",
    repPhoneNumber: "",
  });

  const [collaboratorBankInfo, setCollaboratorBankInfo] = useState({
    colBankPixType: "",
    colBankPixKey: "",
    colBankName: "",
    colBankAgency: "",
    colAccountNumber: "",
    colAccountType: "",
    colAccountDigit: "",
  });

  const showAddressCollaborator = async () => {
    console.log(addressToRegister.regZipCode.length);
    if (addressToRegister.regZipCode.length >= 8) {
      let res = await GET_CEP(addressToRegister.regZipCode);
      console.log(res);

      if (res.erro === true) {
        toast.error("O CEP não existe. Por favor, confira e tente novamente");
      } else {
        setAddressToRegister({
          ...addressToRegister,
          regZipCode: res.cep,
          regStreet: res.logradouro,
          regNeighborhood: res.bairro,
          regCity: res.localidade,
          regState: res.uf,
        });
      }
    }
  };

  const collaboratorRegisterSubmit = async () => {
    setIsLoading(true);
    if (
      (storeInfo.storeType === "1" && !storeInfo.corporateReason) ||
      (storeInfo.storeType === "1" && !representativeInfo.repName) ||
      (storeInfo.storeType === "1" && !representativeInfo.repCPF) ||
      (storeInfo.storeType === "1" && !representativeInfo.repEmail) ||
      (storeInfo.storeType === "1" && !representativeInfo.repPhoneNumber) ||
      !storeInfo.storeType ||
      !storeInfo.typeService ||
      !storeInfo.fantasyName ||
      !colContact.email ||
      !colContact.phone ||
      !storeInfo.document ||
      !addressToRegister.regStreet ||
      !addressToRegister.regZipCode ||
      addressToRegister.regZipCode.length < 8 ||
      !addressToRegister.regNumber ||
      !addressToRegister.regCity ||
      !addressToRegister.regNeighborhood ||
      !addressToRegister.regState ||
      !collaboratorBankInfo.colBankPixType ||
      !collaboratorBankInfo.colBankPixKey ||
      !collaboratorBankInfo.colBankName ||
      !collaboratorBankInfo.colBankAgency ||
      !collaboratorBankInfo.colAccountNumber ||
      !collaboratorBankInfo.colAccountType ||
      !collaboratorBankInfo.colAccountDigit
    ) {
      let emptyField: string | null = null;

      if (!collaboratorBankInfo.colAccountDigit) emptyField = "Dígito";
      if (!collaboratorBankInfo.colAccountType) emptyField = "Tipo de Conta";
      if (!collaboratorBankInfo.colAccountNumber)
        emptyField = "Número da conta";
      if (!collaboratorBankInfo.colBankAgency) emptyField = "Número da Agência";
      if (!collaboratorBankInfo.colBankName) emptyField = "Nome do banco";
      if (!collaboratorBankInfo.colBankPixKey) emptyField = "Chave Pix";
      if (!collaboratorBankInfo.colBankPixType) emptyField = "Tipo de Pix";

      if (storeInfo.storeType === "1" && !representativeInfo.repPhoneNumber)
        emptyField = "Telefone do representante";
      if (storeInfo.storeType === "1" && !representativeInfo.repEmail)
        emptyField = "Email do representante";
      if (storeInfo.storeType === "1" && !representativeInfo.repName)
        emptyField = "Nome do representante";
      if (storeInfo.storeType === "1" && !representativeInfo.repCPF)
        emptyField = "CPF do representante";

      if (!addressToRegister.regState) emptyField = "Estado";
      if (!addressToRegister.regNeighborhood) emptyField = "Bairro";
      if (!addressToRegister.regCity) emptyField = "Cidade";
      if (!addressToRegister.regNumber) emptyField = "Número";
      if (!addressToRegister.regZipCode) emptyField = "CEP";
      if (!addressToRegister.regStreet) emptyField = "Rua";

      if (!storeInfo.document)
        emptyField = storeInfo.storeType === "1" ? "CNPJ" : "CPF";
      if (!colContact.phone) emptyField = "Telefone";
      if (!colContact.email) emptyField = "Email";
      if (!storeInfo.fantasyName)
        emptyField =
          storeInfo.storeType === "1" ? "Nome Fantasia" : "Nome do Colaborador";
      if (storeInfo.storeType === "1" && !storeInfo.corporateReason)
        emptyField = "Razão Social";
      if (!storeInfo.typeService) emptyField = "Atendimento";
      if (!storeInfo.storeType) emptyField = "Tipo de pessoa";

      setIsLoading(false);
      return toast.warning(
        `O campo "${emptyField?.toUpperCase()}" não foi preenchido`
      );
    }

    let dataCollaborator: CollaboratorInfo = {
      tipoLoja: Number(storeInfo.storeType),
      atendimento: Number(storeInfo.typeService),
      razaoSocial: storeInfo.corporateReason,
      nome: storeInfo.fantasyName,
      email: colContact.email,
      phone: colContact.phone,
      document: storeInfo.document.replace(/\D/g, ""),
      endereco: {
        rua: addressToRegister.regStreet,
        cep: addressToRegister.regZipCode,
        numero: addressToRegister.regNumber,
        complemento: addressToRegister.regComplement,
        cidade: addressToRegister.regCity,
        bairro: addressToRegister.regNeighborhood,
        estado: addressToRegister.regState,
      },

      representanteLegal: {
        document: representativeInfo.repCPF.replace(/\D/g, ""),
        nome: representativeInfo.repName,
        email: representativeInfo.repEmail,
        celular: representativeInfo.repPhoneNumber,
      },
      dadosBancarios: {
        tipoPix: Number(collaboratorBankInfo.colBankPixType),
        chavePix: collaboratorBankInfo.colBankPixKey,
        banco: collaboratorBankInfo.colBankName,
        agencia: collaboratorBankInfo.colBankAgency,
        conta: collaboratorBankInfo.colAccountNumber,
        tipoConta: Number(collaboratorBankInfo.colAccountType),
        digito: collaboratorBankInfo.colAccountDigit,
      },
    };

    let collaboratorReq = await REGISTER_COLLABORATOR(dataCollaborator);

    switch (collaboratorReq?.status) {
      case 200:
        toast.success("Colaborador cadastrado com sucesso!");

        setTimeout(() => {
          navigate("/");
        }, 1000);
        break;
      case 409:
        toast.warning("Esse colaborador já está adastrado na plataforma.");
        break;
      case 401:
        toast.error("Sua sessão expirou. Faça login para continuar.", {
          autoClose: 4000,
        });

        setTimeout(() => {
          navigate("/");
        }, 3000);

        break;
      default:
        toast.error(
          "Erro inesperado. Confira os campos e, por favor, tente novamente",
          {
            autoClose: 4000,
          }
        );
        break;
    }
    setIsLoading(false);
  };

  return (
    <div className="collaborator-pre-registration">
      <h1 className="collaborator-header">Pré-cadastro de parceiro</h1>
      <div className="link-to-login">
        <DefaultButton onClick={() => navigate("/")} text="Já tem cadastro?" />
      </div>

      <div className="pre-register">
        <div className="register-store">
          <h4 className="sub-texts">Registro de serviço</h4>
          <div className="grid-col2">
            <DefaultSelect
              htmlFor="store-type"
              labelText="Tipo de Pessoa"
              name="store-type"
              onChange={(e) =>
                setStoreInfo({
                  ...storeInfo,
                  storeType: e.target.value,
                })
              }
              undefinedValueText="Selecione o tipo de entidade"
              options={[
                { text: "Pessoa Física", value: "0" },
                { text: "Pessoa Jurídica", value: "1" },
              ]}
            />
            <DefaultSelect
              htmlFor="store-service-type"
              labelText="Atendimento"
              name="store-service-type"
              onChange={(e) =>
                setStoreInfo({
                  ...storeInfo,
                  typeService: e.target.value,
                })
              }
              undefinedValueText="Selecione a forma de trabalho"
              options={[
                { text: "Presencial", value: 0 },
                { text: "Online", value: 1 },
                { text: "Telefônico (Call Center)", value: 2 },
              ]}
            />
          </div>
          {storeInfo.storeType === "1" && (
            <DefaultInput
              type="text"
              value={storeInfo.corporateReason}
              label="Razão Social"
              onChange={(e) =>
                setStoreInfo({
                  ...storeInfo,
                  corporateReason: e.target.value,
                })
              }
            />
          )}

          <DefaultInput
            type="text"
            value={storeInfo.fantasyName}
            label={
              storeInfo.storeType === "1"
                ? "Nome Fantasia"
                : "Nome do Colaborador"
            }
            onChange={(e) =>
              setStoreInfo({
                ...storeInfo,
                fantasyName: e.target.value,
              })
            }
          />
          <div className="grid-col2">
            <DefaultInput
              type="tel"
              value={colContact.phone}
              label="Telefone"
              onChange={(e) =>
                seColContact({
                  ...colContact,
                  phone: formatCellPhone(e.target.value),
                })
              }
            />
            <DefaultInput
              type="email"
              value={colContact.email}
              label="Email"
              onChange={(e) =>
                seColContact({
                  ...colContact,
                  email: e.target.value,
                })
              }
            />
          </div>
          <div className="grid-col2">
            <DefaultInput
              type="text"
              value={storeInfo.document}
              label={storeInfo.storeType === "1" ? "CNPJ" : "CPF"}
              onChange={(e) =>
                setStoreInfo({
                  ...storeInfo,
                  document: formatUserDocument(e.target.value),
                })
              }
            />
          </div>
        </div>
        {storeInfo.storeType === "1" && (
          <div className="personal-information-collaborator">
            <h4 className="sub-texts">Representante Legal</h4>
            <DefaultInput
              type="text"
              value={representativeInfo.repName}
              label="Nome do representante"
              onChange={(e) =>
                setRepresentativeInfo({
                  ...representativeInfo,
                  repName: e.target.value,
                })
              }
            />
            <div className="grid-col2">
              <DefaultInput
                type="text"
                value={representativeInfo.repCPF}
                label="CPF do representante"
                onChange={(e) =>
                  setRepresentativeInfo({
                    ...representativeInfo,
                    repCPF: formatUserDocument(e.target.value),
                  })
                }
              />
              <DefaultInput
                type="email"
                value={representativeInfo.repEmail}
                label="Email do representante"
                onChange={(e) =>
                  setRepresentativeInfo({
                    ...representativeInfo,
                    repEmail: e.target.value,
                  })
                }
              />
            </div>
            <div className="grid-col2">
              <DefaultInput
                type="tel"
                value={representativeInfo.repPhoneNumber}
                label="Telefone do representante"
                onChange={(e) =>
                  setRepresentativeInfo({
                    ...representativeInfo,
                    repPhoneNumber: formatCellPhone(e.target.value),
                  })
                }
              />
            </div>
          </div>
        )}

        <div className="collaborator-address">
          <h4 className="sub-texts">{`${
            storeInfo.storeType === "1"
              ? "Endereço da Empresa"
              : "Endereço do colaborador"
          }`}</h4>
          <div className="grid-col2">
            <DefaultInput
              type="text"
              value={addressToRegister.regZipCode}
              label="CEP"
              onChange={(e) =>
                setAddressToRegister({
                  ...addressToRegister,
                  regZipCode: e.target.value,
                })
              }
              onBlur={showAddressCollaborator}
            />
          </div>

          <div className="grid-col2">
            <DefaultInput
              type="text"
              value={addressToRegister.regCity}
              label="Cidade"
              onChange={(e) =>
                setAddressToRegister({
                  ...addressToRegister,
                  regCity: e.target.value,
                })
              }
            />
            <DefaultInput
              type="text"
              value={addressToRegister.regState}
              label="Estado"
              onChange={(e) =>
                setAddressToRegister({
                  ...addressToRegister,
                  regState: e.target.value,
                })
              }
            />
          </div>
          <div className="grid-col2">
            <DefaultInput
              type="text"
              value={addressToRegister.regStreet}
              label="Rua"
              onChange={(e) =>
                setAddressToRegister({
                  ...addressToRegister,
                  regStreet: e.target.value,
                })
              }
            />
            <DefaultInput
              type="text"
              value={addressToRegister.regNeighborhood}
              label="Bairro"
              onChange={(e) =>
                setAddressToRegister({
                  ...addressToRegister,
                  regNeighborhood: e.target.value,
                })
              }
            />
          </div>

          <div className="grid-col2">
            <DefaultInput
              type="tel"
              value={addressToRegister.regNumber}
              label="Número"
              onChange={(e) =>
                setAddressToRegister({
                  ...addressToRegister,
                  regNumber: e.target.value,
                })
              }
            />
            <DefaultInput
              type="text"
              value={addressToRegister.regComplement}
              label="Complemento (Opcional)"
              onChange={(e) =>
                setAddressToRegister({
                  ...addressToRegister,
                  regComplement: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="bank-info">
          <h4 className="sub-texts">Dados Bancários</h4>

          <div className="grid-col2">
            <DefaultSelect
              htmlFor="bank-name-select"
              labelText="Nome do banco"
              name="bank-name-select"
              onChange={(e) =>
                setCollaboratorBankInfo({
                  ...collaboratorBankInfo,
                  colBankName: e.target.value,
                })
              }
              undefinedValueText="Selecione o nome do seu banco"
              options={banks.map((bank) => {
                return { text: bank.name, value: bank.name };
              })}
            />
          </div>
          <div className="grid-col2">
            <DefaultInput
              type="text"
              value={collaboratorBankInfo.colBankAgency}
              label="Número da Agência"
              onChange={(e) =>
                setCollaboratorBankInfo({
                  ...collaboratorBankInfo,
                  colBankAgency: e.target.value,
                })
              }
            />

            <DefaultSelect
              htmlFor="bank-account-type-select"
              labelText="Tipo de Conta"
              name="bank-account-type-select"
              onChange={(e) =>
                setCollaboratorBankInfo({
                  ...collaboratorBankInfo,
                  colAccountType: e.target.value,
                })
              }
              undefinedValueText="Selecione o tipo de conta"
              options={[
                { text: "Corrente", value: 0 },
                { text: "Poupança", value: 1 },
              ]}
            />

            <DefaultInput
              type="text"
              value={collaboratorBankInfo.colAccountNumber}
              label="Número da conta"
              onChange={(e) =>
                setCollaboratorBankInfo({
                  ...collaboratorBankInfo,
                  colAccountNumber: e.target.value,
                })
              }
            />
            <DefaultInput
              type="text"
              value={collaboratorBankInfo.colAccountDigit}
              label="Dígito"
              onChange={(e) =>
                setCollaboratorBankInfo({
                  ...collaboratorBankInfo,
                  colAccountDigit: e.target.value,
                })
              }
            />
          </div>
          <div className="grid-col2">
            <DefaultSelect
              htmlFor="pix-type-select"
              labelText="Tipo de Pix"
              name="pix-type-select"
              onChange={(e) =>
                setCollaboratorBankInfo({
                  ...collaboratorBankInfo,
                  colBankPixType: e.target.value,
                })
              }
              undefinedValueText="Selecione o tipo da chave pix"
              options={[
                { text: "CPF", value: "0" },
                { text: "CNPJ", value: "1" },
                { text: "Email", value: "2" },
                { text: "Telefone", value: "3" },
                { text: "Chave Aleatória", value: "4" },
              ]}
            />
            <DefaultInput
              type="text"
              value={collaboratorBankInfo.colBankPixKey}
              label="Chave Pix"
              onChange={(e) =>
                setCollaboratorBankInfo({
                  ...collaboratorBankInfo,
                  colBankPixKey: e.target.value,
                })
              }
            />
          </div>
        </div>
        <DefaultButton
          text="Cadastrar"
          className="button-register-collaborator"
          onClick={collaboratorRegisterSubmit}
          isSubmit={isLoading}
        />
      </div>
    </div>
  );
};

export default CollaboratorPreRegistrationPage;
