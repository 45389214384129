import { AxiosError } from "axios";
import { REQUEST } from "../request";

const VALID_EMAIL = async (email: string, token: string) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: "/customers/valid_email",
      method: "post",
      data: {
        email,
      },
      token,
    });

    return { result: req.data, status: req.status };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default VALID_EMAIL;
