import { useContext } from "react";
import { SellerInfoContext } from "../../../contexts/sellerInfoContext";
import "./style.css";

type DefaultNavBarProps = {
  title: string;
};

const DefaultNavBar = (props: DefaultNavBarProps) => {
  const { title } = props;

  const { sellerInfo } = useContext(SellerInfoContext);
  let userInfoStorage = localStorage.getItem("userDetails");
  let userInfo = JSON.parse(userInfoStorage as string);

  return (
    <nav className="nav-default-container">
      <p className="employee-title">{title}</p>
      <div className="employee-details">
        <p className="employee-name">{userInfo?.name}</p>
        <p className="employee-position">{userInfo?.desc_name}</p>
      </div>
    </nav>
  );
};

export default DefaultNavBar;
