import React, { useContext, useState } from "react";
import NycLogo from "../../assets/nyc-fgts-logo.jpeg";
import DefaultButton from "../../components/Buttons/DefaultButton";
import DefaultInput from "../../components/Inputs/DefaultInput";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/userContext";
import LOGIN from "../../utils/apis/login";
import LOGIN_AUTH from "../../utils/apis/loginAuth";
import { SellerInfoContext } from "../../contexts/sellerInfoContext";
import { Link } from "react-router-dom";
import SELLERS_LIST from "../../utils/apis/getSellersList";
import { SellersListContext } from "../../contexts/sellersListContext";
import ALL_SELLERS from "../../utils/apis/getAllSellers";

const LoginPage = () => {
  const { login } = useContext(AuthContext);
  const { addSellerInfo } = useContext(SellerInfoContext);
  const { addListSeller } = useContext(SellersListContext);
  const navigate = useNavigate();

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [userEmail, setUserEmail] = useState("");
  const [userPass, setUserPass] = useState("");
  const [isWarning, setIsWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loginSubmit = async () => {
    setIsLoading(true);
    let req = await LOGIN(userEmail.trim(), userPass.trim());

    switch (req?.status) {
      case 401:
        setIsWarning(true);
        toast.error(
          "Email ou senha inválidos. Por favor, confira suas credenciais e tente novamente",
          { autoClose: 5000 }
        );

        setTimeout(() => {
          setIsWarning(false);
        }, 3000);

        break;
      case 404:
        setIsWarning(true);
        toast.error(
          "Email ou senha inválidos. Por favor, confira suas credenciais e tente novamente",
          { autoClose: 5000 }
        );

        setTimeout(() => {
          setIsWarning(false);
        }, 3000);

        break;
      case 200:
        login(req.result.token);
        toast.success("Login efetuado com sucesso");

        let authSeller = await LOGIN_AUTH(req.result.token);

        localStorage.clear();
        sessionStorage.clear();
        addSellerInfo(authSeller?.result);
        console.log(authSeller?.result);

        sessionStorage.setItem("@_auth", req.result.token);
        localStorage.setItem("userDetails", JSON.stringify(authSeller?.result));

        if (authSeller?.result?.store_id !== null) {
          let listSellersReq = await ALL_SELLERS(
            page,
            perPage,
            req.result.token
          );

          sessionStorage.setItem("@store", "true");
          addListSeller(listSellersReq?.result);
          return navigate("/search-contract");
        }

        setTimeout(() => {
          navigate("/withdraw-fgts");
        }, 1000);
        break;
      default:
        setIsWarning(true);
        toast.error(
          "Erro inesperado ao tentar efetuar o login. Por favor, tente novamente em alguns minutos",
          { autoClose: 5000 }
        );

        setTimeout(() => {
          setIsWarning(false);
        }, 3000);
        break;
    }
    setIsLoading(false);
  };

  return (
    <div className="login-page">
      <div className="form-login">
        <img className="nyc-logo-fgts" src={NycLogo} alt="nyc bank FGTS icon" />

        <p className="title-form">Faça seu login</p>

        <DefaultInput
          type="email"
          onChange={(e) => setUserEmail(e.target.value)}
          name="email"
          value={userEmail}
          placeholder="Email"
          isWarning={isWarning}
        />

        <DefaultInput
          type="password"
          onChange={(e) => setUserPass(e.target.value)}
          name="password"
          value={userPass}
          placeholder="Senha"
          isWarning={isWarning}
        />

        <DefaultButton
          onClick={loginSubmit}
          text="Acessar"
          isDisabled={isWarning || !userPass || !userPass}
          isSubmit={isLoading}
        />
        <DefaultButton
          onClick={() => navigate("/collaborator-pre-register")}
          text="Ainda não é nosso parceiro?"
        />
      </div>
    </div>
  );
};

export default LoginPage;
