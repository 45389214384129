import { AxiosError } from "axios";
import { Customer } from "../../types/customer";
import { REQUEST } from "../request";

const CUSTOMER_CREATE = async (data: Customer, token: string) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: `/customers`,
      method: "post",
      token,
      data,
    });

    return {
      result: req.data,
      status: req.status,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default CUSTOMER_CREATE;
