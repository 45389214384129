import { AxiosError } from "axios";
import { REQUEST } from "../request";

const SIMULATIONS_LIST = async (pageNumber: number, token: string) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: `/simulations?q[kind_eq]=1&page=${pageNumber}`,
      method: "get",

      token,
    });

    return { result: req.data, status: req.status };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default SIMULATIONS_LIST;
