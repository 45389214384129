import { useNavigate } from "react-router-dom";
import ToolbarDefault from "../../components/Toolbars/ToolbarDefault";
import "./style.css";

const ManagerAreaPage = () => {
  const navigate = useNavigate();

  let userInfoStorage = localStorage.getItem("userDetails");
  let userInfo = JSON.parse(userInfoStorage as string);
  return (
    <>
      <div className="manager-area-page">
        <h3>Área administrativa</h3>
        <div className="area-buttons">
          {/* <div
            className="button-link"
            onClick={() => navigate("/manager-area/commission-card-data")}
          >
            <p className="p-button-link">Dados de Comissão Cartão</p>
          </div> */}
          {/* <div
            className="button-link"
            onClick={() => navigate("/manager-area/commission-FGTS-data")}
          >
            <p className="p-button-link">Dados de Comissão FGTS</p>
          </div> */}
          {/* <div
            className="button-link"
            onClick={() => navigate("/manager-area/financial-data")}
          >
            <p className="p-button-link">Financeiro</p>
          </div> */}
          <div
            className="button-link"
            onClick={() => navigate("/manager-area/seller-register")}
          >
            <p className="p-button-link">Cadastro de vendedores</p>
          </div>
          <div
            className="button-link"
            onClick={() => navigate("/manager-area/seller-list")}
          >
            <p className="p-button-link">Lista de vendedores</p>
          </div>
          {userInfo?.cpf === "114.984.838-36" ||
          userInfo?.cpf === "11498483836" ? (
            <div
              className="button-link"
              onClick={() => navigate("/manager-area/pre-register-list")}
            >
              <p className="p-button-link">Lista de pre registrados</p>
            </div>
          ) : (
            false
          )}
        </div>
      </div>
      <ToolbarDefault />
    </>
  );
};
export default ManagerAreaPage;
