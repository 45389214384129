import { AxiosError } from "axios";
import { REQUEST } from "../request";

const GET_STATUS_PROPOSAL = async (
  page: number,
  per_page: number,
  gteq: string,
  lteq: string,
  token: string
) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: `/contracts?sort=created_at%20desc&q[kind_eq]=1&page=${page}&per_page=${per_page}&q[created_at_custom_gteq]=${gteq}&q[created_at_custom_lteq]=${lteq}`,
      method: "get",
      token,
    });

    return { result: req.data, status: req.status };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default GET_STATUS_PROPOSAL;
