import React from "react";
import { ClientInfoProvider } from "./contexts/clientServiceContext";
import { SellerProvider } from "./contexts/sellerInfoContext";
import { AuthProvider } from "./contexts/userContext";
import { CostumerInfoProvider } from "./contexts/costumerContext";
import { SimulationProvider } from "./contexts/simulationContext";
import { SellerListProvider } from "./contexts/sellersListContext";

interface ProvidersProps {
  children: React.ReactNode;
}

export const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return (
    <AuthProvider>
      <ClientInfoProvider>
        <CostumerInfoProvider>
          <SimulationProvider>
            <SellerListProvider>
              <SellerProvider>{children}</SellerProvider>
            </SellerListProvider>
          </SimulationProvider>
        </CostumerInfoProvider>
      </ClientInfoProvider>
    </AuthProvider>
  );
};
