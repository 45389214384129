import { createContext, useState } from "react";

export const SellersListContext = createContext()

export const SellerListProvider = ({ children }) => {
	const [sellerList, setSellersList] = useState(null)

	const addListSeller = (info) => {
		setSellersList(info)
	}

	const removeListSeller = () => {
		setSellersList(null)
	}

	return (
		<SellersListContext.Provider value={{ sellerList, addListSeller, removeListSeller }}>
			{children}
		</SellersListContext.Provider>
	)

}