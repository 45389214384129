import { ToastContainer, toast } from "react-toastify";
import AsideDefault from "../../components/AsideDefault";
import ToolbarDefault from "../../components/Toolbars/ToolbarDefault";
import "./style.css";
import { useContext, useEffect, useState } from "react";
import CONTRACT_DETAILS_BY_DOC from "../../utils/apis/contractDetailsInfoByDoc";
import { useNavigate } from "react-router-dom";
import { ClientServiceContext } from "../../contexts/clientServiceContext";
import { ConfirmContractDetails } from "../../types/confirmContractDetails";
import CLIENT_DETAILS_BY_ID from "../../utils/apis/detailsInfoById";
import { SimulationsCredit } from "../../types/simulationsCredit";
import DefaultButton from "../../components/Buttons/DefaultButton";
import REGISTER_CONTRACT from "../../utils/apis/registerContract";
import { CostumerContext } from "../../contexts/costumerContext";
import { SimulationContext } from "../../contexts/simulationContext";
import REGISTER_CONTRACT_PROD from "../../utils/apis/registerContractProd";
import Loading from "../../components/Loading";
import { formatDate } from "../../utils/formatDate";
import { formatUserDocument } from "../../utils/formatString";

const ConfirmContractPage = () => {
  const { clientServiceInfo } = useContext(ClientServiceContext);
  const { costumerInfo } = useContext(CostumerContext);
  const { simulationInfo } = useContext(SimulationContext);

  const [contractDetails, setContractDetails] =
    useState<ConfirmContractDetails>();
  const [isLoading, setIsLoading] = useState(false);

  let authStorage = sessionStorage.getItem("@_auth");
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    if (authStorage === "null" || authStorage === null) {
      localStorage.clear();
      sessionStorage.clear();
      return navigate("/");
    }

    const contractDetails = async () => {
      let req = await CONTRACT_DETAILS_BY_DOC(
        clientServiceInfo?.cpf_cnpj,
        authStorage
      );

      let contracts = req?.result;

      if (contracts !== null) {
        setContractDetails(contracts[0]);
        setIsLoading(false);
      }
    };

    // const parcelsDetails = async() =>{
    // 	let req = await CLIENT_DETAILS_BY_ID(costumerInfo?.,authStorage)

    // }

    contractDetails();
    // parcelsDetails()
  }, []);

  const createContract = async () => {
    setIsLoading(true);
    let contractObjContract = {
      contract: {
        name: contractDetails?.name,
        store_name: null,
        contract_value: Number(simulationInfo?.released_amount),
        amount_charged: Number(simulationInfo?.card_limit),
        kind_integrator: 0,
        installments: simulationInfo?.installments,
        customer_id: Number(contractDetails?.id),
        customer_attributes: {
          email: contractDetails?.email,
          birth_date: contractDetails?.birth_date + " 00:00:00",
          mobile: contractDetails?.mobile,
          rg: contractDetails?.rg,
          entity_attributes: {
            name: contractDetails?.name,
            cpf_cnpj: contractDetails?.cpf_cnpj,
            address_attributes: {
              zip_code: contractDetails?.address.zip_code,
              street: contractDetails?.address.street,
              number: contractDetails?.address.number,
              district: contractDetails?.address.district,
              city: contractDetails?.address.city,
              state: contractDetails?.address.state,
              complement: contractDetails?.address.complement,
            },
          },
        },
      },
    };

    //     // let contractObj = {
    //     //   customer: {
    //     //     birth_date: clientServiceInfo.birth_date + " 00:00:00",
    //     //     email: clientServiceInfo.email,
    //     //     mobile: clientServiceInfo.mobile,
    //     //     rg: clientServiceInfo.rg,
    //     //     marital_status: clientServiceInfo.marital_status,
    //     //     gender_customer: clientServiceInfo.gender_customer,
    //     //     mother_name: clientServiceInfo.mother_name,
    //     //     entity_attributes: {
    //     //       name: clientServiceInfo.entity.name,
    //     //       cpf_cnpj: clientServiceInfo.entity.cpf_cnpj,
    //     //       address_attributes: {
    //     //         zip_code: clientServiceInfo.entity.address.zip_code,
    //     //         street: clientServiceInfo.entity.address.street,
    //     //         number: clientServiceInfo.entity.address.number,
    //     //         district: clientServiceInfo.entity.address.district,
    //     //         city: clientServiceInfo.entity.address.city,
    //     //         state: clientServiceInfo.entity.address.state,
    //     //         complement: clientServiceInfo.entity.address.complement,
    //     //       },
    //     //       bank_account_attributes: {
    //     //         number_bank: clientServiceInfo.bank_account.number_bank,
    //     //         name_bank: clientServiceInfo.bank_account.name_bank,
    //     //         agency_account: clientServiceInfo.bank_account.agency_account,
    //     //         number_account: clientServiceInfo.bank_account.number_account,
    //     //         account_digit: clientServiceInfo.bank_account.account_digit,
    //     //         kind: clientServiceInfo.bank_account.kind,
    //     //         kind_account: clientServiceInfo.bank_account.kind_account,
    //     //         month: clientServiceInfo.bank_account.month,
    //     //       },
    //     //     },
    //     //   },
    //     // };

    // // let req = await REGISTER_CONTRACT_BY_CUSTOMER_SERVICE_ID(
    // //   contractObj,
    // //   simulationInfo?.id,
    // //   authStorage as string
    // // );

    console.log(contractObjContract);

    let reqRegister = await REGISTER_CONTRACT(
      //@ts-ignore
      contractObjContract,
      simulationInfo?.id,
      authStorage as string
    );

    // let objRequest = {
    //   contract: {
    //     contract_value: simulationInfo?.released_amount
    //       ? Number(simulationInfo?.released_amount)
    //       : Number(clientServiceInfo.released_amount),
    //     amount_charged: simulationInfo?.card_limit
    //       ? Number(simulationInfo?.card_limit)
    //       : Number(clientServiceInfo.card_limit),
    //     installments: simulationInfo?.installments
    //       ? Number(simulationInfo?.installments)
    //       : Number(clientServiceInfo.installments),
    //     customer_id: clientServiceInfo.id
    //       ? Number(clientServiceInfo.id)
    //       : Number(clientServiceInfo.customer_id),
    //     kind_integrator: 0,
    //   },
    //   simulation_id: simulationInfo?.id
    //     ? simulationInfo?.id
    //     : clientServiceInfo.id,
    // };

    // console.log(objRequest);

    // let reqRegister = await REGISTER_CONTRACT_PROD(
    //   objRequest,
    //   authStorage as string
    // );

    switch (reqRegister?.status) {
      case 201:
        toast.success("Simulação cadastrada com sucesso!");

        setTimeout(() => {
          navigate("/search-contract");
        }, 1000);
        break;
      case 400:
        toast.error(
          `${
            reqRegister?.result?.response?.data?.message ||
            "Verifique os campos do contrato e verifique se existe algum erro de digitação."
          }`
        );
        return navigate("/dashboard/add-client");

      default:
        toast.error(
          `${
            reqRegister?.result?.response?.data?.message ||
            "Verifique os campos do contrato e verifique se existe algum erro de digitação."
          }`
        );
        return navigate("/dashboard/add-client");
    }

    setIsLoading(false);
  };

  return (
    <div className="confirm-contract">
      <AsideDefault />
      <ToolbarDefault />
      <div className="main-confirm-contract">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <h3>Criar um contrato</h3>
            <div className="client-info">
              <p className="title-info">INFORMAÇÕES PESSOAIS</p>
              {contractDetails?.id && (
                <div className="detail">
                  <p className="detail-text">NOME COMPLETO</p>
                  <p className="detail-text sub-text text-link">
                    {contractDetails?.name}
                  </p>
                </div>
              )}

              <div className="detail">
                <p className="detail-text">CPF</p>
                <p className="detail-text sub-text">
                  {formatUserDocument(contractDetails?.cpf_cnpj)}
                </p>
              </div>

              <div className="detail">
                <p className="detail-text">EMAIL</p>
                <p className="detail-text sub-text">{contractDetails?.email}</p>
              </div>

              <div className="detail">
                <p className="detail-text">DATA DE NASCIMENTO</p>
                <p className="detail-text sub-text">
                  {/* @ts-ignore */}
                  {formatDate(contractDetails?.birth_date + " 00:00:00")}
                </p>
              </div>

              <div className="detail">
                <p className="detail-text">RG</p>
                <p className="detail-text sub-text">{contractDetails?.rg}</p>
              </div>
              <div className="detail">
                <p className="detail-text">CELULAR</p>
                <p className="detail-text sub-text">
                  {contractDetails?.mobile}
                </p>
              </div>
            </div>{" "}
            <div className="client-address">
              <p className="title-info">ENDEREÇO DO CLIENTE</p>
              <div className="detail">
                <p className="detail-text">CEP</p>
                <p className="detail-text sub-text">
                  {contractDetails?.address?.zip_code}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">ENDEREÇO</p>
                <p className="detail-text sub-text">
                  {contractDetails?.address?.street}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">NÚMERO</p>
                <p className="detail-text sub-text">
                  {contractDetails?.address?.number}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">BAIRRO</p>
                <p className="detail-text sub-text">
                  {contractDetails?.address?.district}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">CIDADE</p>
                <p className="detail-text sub-text">
                  {contractDetails?.address?.city}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">ESTADO</p>
                <p className="detail-text sub-text">
                  {contractDetails?.address?.state}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">COMPLEMENTO</p>
                <p className="detail-text sub-text">
                  {contractDetails?.address?.complement}
                </p>
              </div>
            </div>
            <div className="client-bank-info">
              <p className="title-info">DADOS BANCÁRIOS</p>
              <div className="detail">
                <p className="detail-text">NOME DO BANCO</p>
                <p className="detail-text sub-text">
                  {contractDetails?.bank_account?.name_bank}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">AGÊNCIA</p>
                <p className="detail-text sub-text">
                  {contractDetails?.bank_account?.agency_account}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">CONTA</p>
                <p className="detail-text sub-text">
                  {contractDetails?.bank_account?.number_account}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">DÍGITO</p>
                <p className="detail-text sub-text">
                  {contractDetails?.bank_account?.account_digit}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">TIPO DE CONTA</p>
                <p className="detail-text sub-text">
                  {contractDetails?.bank_account?.kind === 0
                    ? "CONTA CORRENTE"
                    : "CONTA POUPANÇA"}
                </p>
              </div>
            </div>{" "}
            <div className="client-contract-info">
              <p className="title-info">INFORMAÇÕES DO CONTRATO</p>
              <div className="detail">
                <p className="detail-text">Valor do Contrato</p>
                <p className="detail-text sub-text">
                  {simulationInfo?.released_amount
                    ? `R$ ${Number(simulationInfo?.released_amount).toFixed(2)}`
                    : `R$ ${Number(clientServiceInfo?.released_amount).toFixed(
                        2
                      )}`}
                </p>
              </div>

              <div className="detail">
                <p className="detail-text">Qtde. Parcelas</p>
                <p className="detail-text sub-text">
                  {simulationInfo?.installments
                    ? simulationInfo?.installments
                    : clientServiceInfo?.installments}
                </p>
              </div>
            </div>
            <DefaultButton
              text="Finalizar"
              className="create-contract-button"
              onClick={createContract}
              isSubmit={isLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmContractPage;
