import { AxiosError } from "axios";
import { REQUEST } from "../request";

const CREATE_FGTS = async (data: any, token: string) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: "/simulations/create_fgts",
      method: "post",
      token,
      data,
    });

    return {
      result: req.data.simulation,
      status: req.status,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default CREATE_FGTS;
