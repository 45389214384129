import { useContext, useEffect, useState } from "react";
import "./style.css";
import SIMULATIONS_LIST from "../../utils/apis/simulationsList";
import { AuthContext } from "../../contexts/userContext";
import { SellerInfoContext } from "../../contexts/sellerInfoContext";
import SELLERS_LIST from "../../utils/apis/getSellersList";
import { formatDate } from "../../utils/formatDate";
import CLIENTS_DETAILS_BY_ID from "../../utils/apis/detailsInfoById";
import { ClientServiceContext } from "../../contexts/clientServiceContext";
import { useNavigate } from "react-router-dom";
import AsideDefault from "../../components/AsideDefault";
import ToolbarDefault from "../../components/Toolbars/ToolbarDefault";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../components/Loading";
import VMasker from "vanilla-masker";
import DefaultNavBar from "../../components/Navs/DefaultNavBar";
import { SimulationContext } from "../../contexts/simulationContext";

const SearchContractPage = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [simulationsList, setSimulationsList] = useState([]);
  const { sellerInfo, clearSellerInfo } = useContext(SellerInfoContext);
  const { addClientInfo, removeClientInfo, clientServiceInfo } =
    useContext(ClientServiceContext);
  const { addSimulationInfo } = useContext(SimulationContext);

  const [isPromoter, setIsPromoter] = useState(false);
  const [sellersList, setSellersList] = useState([]);
  const [salesName, setSalesName] = useState("");
  const [position, setPosition] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  let authStorage = sessionStorage.getItem("@_auth");

  useEffect(() => {
    setIsLoading(true);
    if (authStorage === "null" || authStorage === null) {
      localStorage.clear();
      sessionStorage.clear();
      setIsLoading(false);
      return navigate("/");
    }

    const reqSimulationsList = async () => {
      let req = await SIMULATIONS_LIST(pageNumber, authStorage);

      setSimulationsList(req?.result);
      if (sellerInfo?.store_id !== null) {
        let listSellersReq = await SELLERS_LIST(
          sellerInfo?.store_id,
          authStorage
        );
        setIsPromoter(true);
        setSellersList(listSellersReq?.result);
      }
    };

    reqSimulationsList();

    setSalesName(sellerInfo?.name);
    setPosition(sellerInfo?.desc_name);
    setIsLoading(false);
  }, []);

  const detailsRoute = (id: string | number) => {
    localStorage.setItem("_client_info_id", id?.toString());
    navigate("/dashboard/details-client");
  };

  const registerRoute = async (simulation: any) => {
    console.log("simulation");
    console.log(simulation);
    addSimulationInfo(simulation);
    console.log("simulation");

    localStorage.setItem("customer_id", simulation?.customer_id);
    let infoReq = await CLIENTS_DETAILS_BY_ID(
      simulation.id,
      authStorage as string
    );

    switch (infoReq?.status) {
      case 200:
        addClientInfo(infoReq.result);

        if (simulation.status_i18n === "Simulação Realizada") {
          navigate("/dashboard/confirm-contract");
        } else {
          navigate("/dashboard/add-client");
        }

        break;
      case 401:
        toast.error("Sua sessão expirou. Faça login para continuar.", {
          autoClose: 4000,
        });

        setTimeout(() => {
          navigate("/");
        }, 3000);
        break;
    }
  };

  const formatTaxNumber = (taxNumber: string) => {
    let numbers = taxNumber.match(/\d+/g)?.join("");

    if (numbers?.length === 11) {
      return VMasker.toPattern(numbers, "999.999.999-10");
    } else if (numbers?.length === 14) {
      return VMasker.toPattern(numbers, "99.999.999/9999-99");
    } else {
      return taxNumber;
    }
  };

  return (
    <div className="search-contract">
      <AsideDefault />
      <ToolbarDefault />
      <main className="main-container-search">
        <DefaultNavBar title="CONTRATOS FGTS" />

        <>
          {isLoading || !simulationsList.length ? (
            <Loading />
          ) : (
            <div className="table-info">
              <table className="main-table">
                <tbody className="main-table-body">
                  <tr className="header-table">
                    <th className="th">ID</th>
                    <th className="th">NOME</th>
                    <th className="th">CPF</th>
                    <th className="th">TELEFONE</th>
                    <th className="th">STATUS</th>
                    <th className="th">ULT. ATUALIZAÇÃO</th>
                    <th className="th actions-table-body">VER DETALHES</th>
                    <th className="th actions-table-body">AÇÕES</th>
                  </tr>

                  {simulationsList?.map((simulation, index) => (
                    <tr key={index} className="simulation-row">
                      {/* @ts-ignore */}
                      <td className="simulation-td">{simulation.id}</td>
                      {/* @ts-ignore */}
                      <td className="simulation-td">{simulation.name}</td>
                      <td className="simulation-td">
                        {/* @ts-ignore */}
                        {formatTaxNumber(simulation?.cpf_cnpj)}
                      </td>
                      {/* @ts-ignore */}
                      <td className="simulation-td">{simulation.phone}</td>

                      <td className="simulation-td">
                        {/* @ts-ignore */}
                        {simulation.status_i18n}
                      </td>
                      <td className="simulation-td">
                        {/* @ts-ignore */}
                        {formatDate(simulation.updated_at)}
                      </td>
                      <td>
                        <button
                          className="menu-icon-button"
                          /* @ts-ignore */
                          onClick={() => detailsRoute(simulation.id)}
                        >
                          Detalhes
                        </button>
                      </td>
                      <td style={{ paddingRight: "8px" }}>
                        {/* @ts-ignore */}
                        {simulation.status_i18n === "Contrato Cadastrado" ? (
                          false
                        ) : (
                          <button
                            className="menu-icon-button"
                            /* @ts-ignore */
                            onClick={() => registerRoute(simulation)}
                          >
                            {/* @ts-ignore  */}
                            {simulation.status_i18n === "Simulação Realizada"
                              ? "Criar contrato"
                              : "Cadastrar"}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      </main>
    </div>
  );
};

export default SearchContractPage;
