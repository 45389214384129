import { createBrowserRouter } from "react-router-dom";
import LoginPage from "../pages/loginPage";
import WithdrawFGTSPage from "../pages/withdrawFGTSPage";
import AddClientPage from "../pages/addClientPage";
import CollaboratorPreRegistrationPage from "../pages/collaboratorPreRegistrationPage";
import ProposalStatusPage from "../pages/proposalStatus";
import SearchContractPage from "../pages/searchContract";
import DetailsContractPage from "../pages/detailsContract";
import DetailsClientPage from "../pages/detailsClient";
import ConfirmContractPage from "../pages/confirmContractPage.tsx/indext";
import ManagerAreaPage from "../pages/managerArea";
import CommissionCardData from "../pages/managerArea/commissionCardData";
import FinancialDataPage from "../pages/managerArea/financialData";
import SellerRegisterPage from "../pages/managerArea/sellerRegister";
import CommissionFGTSDataPage from "../pages/managerArea/commissionFGTSData";
import SellersListPage from "../pages/managerArea/listSeller.tsx";
import PreRegisteredList from "../pages/managerArea/preRegistersList";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/withdraw-fgts",
    element: <WithdrawFGTSPage />,
  },
  {
    path: "/search-contract",
    element: <SearchContractPage />,
  },

  {
    path: "/dashboard/add-client",
    element: <AddClientPage />,
  },
  {
    path: "/dashboard/details-contract",
    element: <DetailsContractPage />,
  },
  {
    path: "/dashboard/details-client",
    element: <DetailsClientPage />,
  },
  {
    path: "/dashboard/confirm-contract",
    element: <ConfirmContractPage />,
  },
  {
    path: "/collaborator-pre-register",
    element: <CollaboratorPreRegistrationPage />,
  },
  {
    path: "/proposal-status",
    element: <ProposalStatusPage />,
  },
  {
    path: "/manager-area",
    element: <ManagerAreaPage />,
  },
  {
    path: "/manager-area/commission-card-data",
    element: <CommissionCardData />,
  },
  {
    path: "/manager-area/commission-FGTS-data",
    element: <CommissionFGTSDataPage />,
  },
  {
    path: "/manager-area/financial-data",
    element: <FinancialDataPage />,
  },
  {
    path: "/manager-area/seller-register",
    element: <SellerRegisterPage />,
  },
  {
    path: "/manager-area/seller-list",
    element: <SellersListPage />,
  },
  {
    path: "/manager-area/pre-register-list",
    element: <PreRegisteredList />,
  },
]);

export default router;
