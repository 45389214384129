import { AxiosError } from "axios";
import { REQUEST } from "../request";

const SELLERS_LIST = async (store_id: string, token: string) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: `/sellers/searchs?q[store_id_eq]=${store_id}`,
      method: "get",

      token,
    });

    return { result: req.data, status: req.status };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default SELLERS_LIST;
