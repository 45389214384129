export const banks = [
  {
    id: 1,
    code: "001",
    name: "Banco do Brasil S.A.",
  },
  {
    id: 17,
    code: "033",
    name: "Banco Santander (Brasil) S.A.",
  },
  {
    id: 21,
    code: "041",
    name: "Banco do Estado do Rio Grande do Sul S.A.",
  },
  {
    id: 31,
    code: "070",
    name: "BRB - Banco de Brasília S.A.",
  },
  {
    id: 35,
    code: "077",
    name: "Banco Inter S.A.",
  },
  {
    id: 43,
    code: "085",
    name: "Cooperativa Central de Crédito - AILOS",
  },
  {
    id: 57,
    code: "104",
    name: "CAIXA ECONÔMICA FEDERAL (CEF)",
  },
  {
    id: 112,
    code: "212",
    name: "Banco Original S.A.",
  },
  {
    id: 119,
    code: "237",
    name: "BRADESCO",
  },
  {
    id: 127,
    code: "260",
    name: "Nu Pagamentos S.A. (nubank)",
  },
  {
    id: 163,
    code: "336",
    name: "Bco C6 S.A.",
  },
  {
    id: 164,
    code: "341",
    name: "ITAÚ",
  },
  {
    id: 172,
    code: "422",
    name: "Banco Safra S.A.",
  },
  {
    id: 213,
    code: "748",
    name: "Sicredi Banco Cooperativo",
  },
  {
    id: 219,
    code: "756",
    name: "Sicoob - BANCOOB",
  },
];
