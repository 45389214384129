import { createContext, useState } from "react";

export const ClientServiceContext = createContext()

export const ClientInfoProvider = ({ children }) => {
	const [clientServiceInfo, setClientServiceInfo] = useState(null)

	const addClientInfo = (info) => {
		setClientServiceInfo(info);
	};

	const removeClientInfo = () => {
		setClientServiceInfo(null);
	};

	return (
		<ClientServiceContext.Provider value={{ clientServiceInfo, addClientInfo, removeClientInfo }}>
			{children}
		</ClientServiceContext.Provider>
	)
}