import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import CONTRACT_DETAILS_BY_ID from "../../utils/apis/contractDetailsInfoById";
import { AuthContext } from "../../contexts/userContext";
import DefaultButton from "../../components/Buttons/DefaultButton";
import AsideDefault from "../../components/AsideDefault";
import ToolbarDefault from "../../components/Toolbars/ToolbarDefault";
import { ToastContainer, toast } from "react-toastify";
import GET_CONTRACT_BY_ID from "../../utils/apis/getContractById";
import { ContractDetails } from "../../types/contractDetails";
import { useNavigate } from "react-router-dom";
import VMasker from "vanilla-masker";
import { dateMask } from "../../utils/masks";
import { formatDate } from "../../utils/formatDate";
import Loading from "../../components/Loading";

const DetailsContractPage = () => {
  const [contractDetails, seContractDetails] = useState<ContractDetails>();
  const [sellerDetails, setSellerDetails] = useState({
    created_at: "",
    id: 0,
    id_contract: 0,
    name: "",
    page_type: "",
    updated_at: "",
    user_id: 0,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [infoId, setInfoId] = useState("");
  const navigate = useNavigate();
  let authStorage = sessionStorage.getItem("@_auth");

  useEffect(() => {
    setIsLoading(true);
    if (authStorage === "null" || authStorage === null) {
      localStorage.clear();
      sessionStorage.clear();
      setIsLoading(false);
      return navigate("/");
    }
    let contractId = localStorage.getItem("_contract_details_id");

    const contractDetail = async () => {
      const req = await CONTRACT_DETAILS_BY_ID(
        contractId as string,
        authStorage
      );

      switch (req?.status) {
        case 401:
          toast.error("Sua sessão expirou. Faça login para continuar.", {
            autoClose: 4000,
          });

          setTimeout(() => {
            navigate("/");
          }, 3000);
          break;
        case 200:
          setSellerDetails(req.result[0]);
          break;
      }
    };

    const detailsById = async () => {
      let infoReq = await GET_CONTRACT_BY_ID(contractId as string, authStorage);

      switch (infoReq?.status) {
        case 401:
          toast.error("Sua sessão expirou. Faça login para continuar.", {
            autoClose: 4000,
          });

          setTimeout(() => {
            navigate("/");
          }, 3000);
          break;
        case 200:
          seContractDetails(infoReq?.result);
          break;
      }
    };

    if (contractId !== "null") {
      setInfoId(infoId);
      detailsById();
      contractDetail();
    }
    setIsLoading(false);
  }, []);

  return (
    <div className="details-contract">
      <AsideDefault />
      <ToolbarDefault />
      <div className="details-client-main">
        {isLoading || !contractDetails ? (
          <Loading />
        ) : (
          <>
            <h4 className="sub-header-text">CONTRATOS</h4>

            <div className="details-sales-info">
              <div className="sales-info">
                <p className="title-info">INFORMAÇÕES DO VENDEDOR</p>
                <div className="detail">
                  <p className="detail-text">Nome do vendedor</p>
                  <p className="detail-text sub-text">
                    {contractDetails?.seller_name}
                  </p>
                </div>
                <div className="detail">
                  <p className="detail-text">Número do vendedor</p>
                  <p className="detail-text sub-text">
                    {contractDetails?.mobile_seller}
                  </p>
                </div>
                <div className="detail">
                  <p className="detail-text">Número da loja</p>
                  <p className="detail-text sub-text">
                    {contractDetails?.mobile_store}
                  </p>
                </div>

                <div className="detail">
                  <p className="detail-text">Substabelecimento</p>

                  <p className="detail-text sub-text">
                    {contractDetails?.sub_name}
                  </p>
                </div>
                <div className="detail">
                  <p className="detail-text">Loja</p>

                  <p className="detail-text sub-text">
                    {contractDetails?.store_name}
                  </p>
                </div>

                <div className="detail">
                  <p className="detail-text">Corban</p>
                  <p className="detail-text sub-text">
                    {contractDetails?.corban_name}
                  </p>
                </div>
                <div className="detail">
                  <p className="detail-text">Gerente comercial</p>
                  <p className="detail-text sub-text">
                    {contractDetails?.user_manager_name}
                  </p>
                </div>
              </div>
              <div className="client-info">
                <p className="title-info">Informações pessoais</p>
                {contractDetails?.id && (
                  <div className="detail">
                    <p className="detail-text">Nome completo</p>
                    <p className="detail-text sub-text text-link">
                      {contractDetails?.customer.name}
                    </p>
                  </div>
                )}

                <div className="detail">
                  <p className="detail-text">CPF</p>
                  <p className="detail-text sub-text">
                    {VMasker.toPattern(
                      contractDetails?.customer?.cpf_cnpj
                        ? contractDetails?.customer?.cpf_cnpj
                        : ("" as string),
                      "999.999.999-99"
                    )}
                  </p>
                </div>

                <div className="detail">
                  <p className="detail-text">Email</p>
                  <p className="detail-text sub-text">
                    {contractDetails?.customer.email}
                  </p>
                </div>

                <div className="detail">
                  <p className="detail-text">Data de nascimento</p>
                  <p className="detail-text sub-text">
                    {/*@ts-ignore */}
                    {formatDate(
                      contractDetails?.customer.birth_date + " 00:00:00"
                    )}
                  </p>
                </div>
                <div className="detail">
                  <p className="detail-text">RG</p>
                  <p className="detail-text sub-text">
                    {contractDetails?.customer.rg}
                  </p>
                </div>
                <div className="detail">
                  <p className="detail-text">Celular</p>
                  <p className="detail-text sub-text">
                    {contractDetails?.customer.mobile}
                  </p>
                </div>
              </div>{" "}
            </div>

            <div className="client-address">
              <p className="title-info">Endereço do cliente</p>
              <div className="detail">
                <p className="detail-text">CEP</p>
                <p className="detail-text sub-text">
                  {contractDetails?.customer.address.zip_code}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Endereço</p>
                <p className="detail-text sub-text">
                  {contractDetails?.customer.address.street}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Número</p>
                <p className="detail-text sub-text">
                  {contractDetails?.customer.address.number}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Bairro</p>
                <p className="detail-text sub-text">
                  {contractDetails?.customer.address.district}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Cidade</p>
                <p className="detail-text sub-text">
                  {contractDetails?.customer.address.city}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Estado</p>
                <p className="detail-text sub-text">
                  {contractDetails?.customer.address.state}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Complemento</p>
                <p className="detail-text sub-text">
                  {contractDetails?.customer.address.complement}
                </p>
              </div>
            </div>
            <div className="client-bank-info">
              <p className="title-info">Dados bancários</p>
              <div className="detail">
                <p className="detail-text">Nome do banco</p>
                <p className="detail-text sub-text">
                  {contractDetails?.customer.bank_account.name_bank}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Agência</p>
                <p className="detail-text sub-text">
                  {contractDetails?.customer.bank_account.agency_account}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Conta</p>
                <p className="detail-text sub-text">
                  {contractDetails?.customer.bank_account.number_account}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Dígito</p>
                <p className="detail-text sub-text">
                  {contractDetails?.customer.bank_account.account_digit}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Tipo de conta</p>
                <p className="detail-text sub-text">
                  {contractDetails?.customer.bank_account.kind === 0
                    ? "CONTA CORRENTE"
                    : "CONTA POUPANÇA"}
                </p>
              </div>
              {contractDetails?.customer.bank_account.pix && (
                <>
                  <div className="detail">
                    <p className="detail-text">Tipo de pix</p>
                    <p className="detail-text sub-text">
                      {contractDetails?.customer.bank_account.kind_pix}
                    </p>
                  </div>
                  <div className="detail">
                    <p className="detail-text">PIX</p>
                    <p className="detail-text sub-text">
                      {contractDetails?.customer.bank_account?.pix}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="client-contract-info">
              <p className="title-info">Informações do contrato</p>
              <div className="detail">
                <p className="detail-text">Número do contrato</p>
                <p className="detail-text sub-text">
                  {contractDetails?.contract_number}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Valor líquido</p>
                <p className="detail-text sub-text">
                  {`R$ ${Number(contractDetails?.contract_value).toFixed(2)}`}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Grade</p>
                <p className="detail-text sub-text">
                  {contractDetails?.tac_table_name}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Qtde. de parcelas</p>
                <p className="detail-text sub-text">
                  {contractDetails?.installments}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Taxa de juros</p>
                <p className="detail-text sub-text">
                  {contractDetails?.basic_interest}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DetailsContractPage;
