import { ChangeEventHandler, FocusEventHandler } from "react";
import "./style.css";

type DefaultInputProps = {
  type: "password" | "email" | "text" | "number" | "tel" | "date";
  value: string | number | readonly string[] | undefined;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  readOnly?: boolean;
  onFocus?: FocusEventHandler<HTMLInputElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement> | undefined;
  autoFocus?: boolean | undefined;
  name?: string;
  className?: string;
  placeholder?: string;
  isWarning?: boolean;
  label?: string;
};

const DefaultInput = (props: DefaultInputProps) => {
  const {
    type,
    value,
    onChange,
    autoFocus,
    onBlur,
    onFocus,
    readOnly,
    name,
    className,
    placeholder,
    isWarning,
    label,
  } = props;

  return (
    <div className="input-label-container">
      {label && (
        <label className="input-label" htmlFor={name}>
          {label}
        </label>
      )}

      <input
        id={name}
        className={`default-input ${className} ${isWarning ? "warning" : ""}`}
        type={type}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        onFocus={onFocus}
        onBlur={onBlur}
        autoFocus={autoFocus}
        name={name}
        placeholder={placeholder}
      />
    </div>
  );
};

export default DefaultInput;
