/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import CLIENT_DETAILS_BY_ID from "../../utils/apis/detailsInfoById";
import { AuthContext } from "../../contexts/userContext";
import DefaultButton from "../../components/Buttons/DefaultButton";
import AsideDefault from "../../components/AsideDefault";
import { ToastContainer, toast } from "react-toastify";
import ToolbarDefault from "../../components/Toolbars/ToolbarDefault";
import { useNavigate } from "react-router-dom";
import { ServiceDetails } from "../../types/serviceDetails";
import VMasker from "vanilla-masker";
import Loading from "../../components/Loading";
import { SimulationContext } from "../../contexts/simulationContext";

const DetailsClientPage = () => {
  const navigate = useNavigate();
  const [infoId, setInfoId] = useState("");
  const [detailsInfo, setDetailsInfo] = useState<ServiceDetails>();
  const [isLoading, setIsLoading] = useState(false);
  const { addSimulationInfo } = useContext(SimulationContext);

  let infoIdStorage = localStorage.getItem("_client_info_id");

  let authStorage = sessionStorage.getItem("@_auth");

  useEffect(() => {
    setIsLoading(true);
    if (authStorage === "null" || authStorage === null) {
      localStorage.clear();
      sessionStorage.clear();
      setIsLoading(false);
      return navigate("/");
    }

    const detailsById = async () => {
      if (infoIdStorage !== "null" || infoIdStorage !== null) {
        let infoReq = await CLIENT_DETAILS_BY_ID(
          infoIdStorage as string,
          authStorage
        );

        switch (infoReq?.status) {
          case 401:
            toast.error("Sua sessão expirou. Faça login para continuar.", {
              autoClose: 4000,
            });

            setTimeout(() => {
              navigate("/");
            }, 3000);
            break;
          case 200:
            setDetailsInfo(infoReq?.result);
            addSimulationInfo(infoReq?.result);
            break;
        }
      }
    };

    setInfoId(infoId);
    detailsById();
    setIsLoading(false);
  }, []);

  const contractPage = (id: any) => {};

  return (
    <div className="details-client">
      <AsideDefault />
      <ToolbarDefault />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="details-client-main">
          <h3 className="header-page">ATENDIMENTOS CARTÃO</h3>
          <h4 className="sub-header-text">Detalhes do Atendimento</h4>
          {detailsInfo?.status === "contract_created" && (
            <a
              className="link-button"
              target="_blank"
              href={`https://sistema.novosaque.com.br/ccb_download/${detailsInfo?.contract_id}`}
              rel="noreferrer"
            >
              <DefaultButton
                className="download-ccb-button"
                text="Baixar CCB assinada"
              />
            </a>
          )}

          <div className="details-sales-info">
            <div className="sales-info">
              <p className="title-info">INFORMAÇÕES DO VENDEDOR</p>
              <div className="detail">
                <p className="detail-text">Nome do Vendedor</p>
                <p className="detail-text sub-text">
                  {detailsInfo?.seller_name}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Subestabelecimento</p>
                <p className="detail-text sub-text">{detailsInfo?.sub_name}</p>
              </div>
              <div className="detail">
                <p className="detail-text">Loja</p>
                <p className="detail-text sub-text">
                  {detailsInfo?.store_name}
                </p>
              </div>
              <div className="detail">
                <p className="detail-text">Corban</p>
                <p className="detail-text sub-text">
                  {detailsInfo?.corban_name}
                </p>
              </div>
            </div>
            <div className="client-info">
              <p className="title-info">INFORMAÇÕES PESSOAIS</p>
              {detailsInfo?.status === "contract_created" && (
                <div className="client-main">
                  <div className="detail">
                    <p className="detail-text">ID Contrato</p>
                    <p
                      className="detail-text sub-text text-link"
                      onClick={() => contractPage(detailsInfo?.id)}
                    >
                      {detailsInfo?.id}
                    </p>
                  </div>
                </div>
              )}
              <div className="client-main">
                <div className="detail">
                  <p className="detail-text">CPF</p>
                  <p className="detail-text sub-text">
                    {detailsInfo &&
                      VMasker.toPattern(
                        detailsInfo?.client_cpf_cnpj as string,
                        "999.999.999-10"
                      )}
                  </p>
                </div>
              </div>
              <div className="client-main">
                <div className="detail">
                  <p className="detail-text">Email</p>
                  <p className="detail-text sub-text">{detailsInfo?.email}</p>
                </div>
              </div>
              <div className="client-main">
                <div className="detail">
                  <p className="detail-text">Valor Liquido</p>
                  <p className="detail-text sub-text">
                    {`R$ ${Number(detailsInfo?.released_amount).toFixed(2)}`}
                  </p>
                </div>
              </div>
              <div className="client-main">
                <div className="detail">
                  <p className="detail-text">Parcelas</p>
                  <p className="detail-text sub-text">
                    {detailsInfo?.installments}
                  </p>
                </div>
              </div>
              <div className="client-main">
                <div className="detail">
                  <p className="detail-text">Nome</p>
                  <p className="detail-text sub-text">{detailsInfo?.name}</p>
                </div>
              </div>
              <div className="client-main">
                <div className="detail">
                  <p className="detail-text">Telefone</p>
                  <p className="detail-text sub-text">{detailsInfo?.phone}</p>
                </div>
              </div>
              <div className="client-main">
                <div className="detail">
                  <p className="detail-text">Valor Total Retido</p>
                  <p className="detail-text sub-text">
                    {`R$ ${Number(detailsInfo?.card_limit).toFixed(2)}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsClientPage;
