import VMasker from "vanilla-masker";

export const cpfMask = (
  /** @type {{ target: Element | NodeListOf<Element>; }} */ event
) => {
	VMasker(event.target).unMask();
	VMasker(event.target).maskPattern("999.999.999-99");
};

export const cnpjMask = (
  /** @type {{ target: Element | NodeListOf<Element>; }} */ event
) => {
	VMasker(event.target).unMask();
	VMasker(event.target).maskPattern("99.999.999/9999-99");
};

export const phoneMask = (
  /** @type {{ target: Element | NodeListOf<Element>; }} */ event
) => {
	VMasker(event.target).unMask();
	VMasker(event.target).maskPattern("(99) 99999-9999");
};

export const landlineMask = (
	/** @type {{ target: Element | NodeListOf<Element>; }} */ event
) => {
	VMasker(event.target).unMask();
	VMasker(event.target).maskPattern("(99) 9999-9999");
};

export const propertyMask = (
	  /** @type {{ target: Element | NodeListOf<Element>; }} */ event
) => {
	VMasker(event.target).unMask();
	VMasker(event.target).maskPattern("99");
};

export const passwordMask = (
  /** @type {{ target: Element | NodeListOf<Element>; }} */ event
) => {
	VMasker(event.target).unMask();
	VMasker(event.target).maskPattern("999999");
};

export const CEPMask = (
  /** @type {{ target: Element | NodeListOf<Element>; }} */ event
) => {
	VMasker(event.target).unMask();
	VMasker(event.target).maskPattern("99999-999");
};

export const maskMoney = (/** @type {import("react").ChangeEvent<HTMLInputElement>} */ el) => {
	VMasker(el.target).unMask();
	VMasker(el.target).maskMoney({
		// Decimal precision -> "90"
		precision: 2,
		// Decimal separator -> ",90"
		separator: ",",
		// Number delimiter -> "12.345.678"
		delimiter: ".",
		// Money unit -> "R$ 12.345.678,90"
		unit: "R$",
	});
};

// @ts-ignore
export const maskMoneyConverter = (event) => {

	let numbers = event.target.value.replace(/\D/g, '');

	if (numbers && numbers.length) {
		while (numbers.length && numbers.length > 0 && numbers[0] == '0') {
			numbers = numbers.substring(1);
		}

		if (numbers && numbers.length) {
			switch (numbers.length) {
				case 1:
					return `0,0${numbers[0]}`

				case 2:
					return `0,${numbers[0]}${numbers[1]}`

				default:
					let valueMasked = '';

					for (let index = 0; index < numbers.length; index++) {
						if (index == numbers.length - 2) {
							valueMasked += ',';
						}
						valueMasked += numbers[index];
					}

					var valuesMasked = valueMasked.split(',');
					let res = [];

					for (let index = 0; index < valuesMasked[0].length; index++) {
						res.push(valuesMasked[0][index]);

						let qtdRestItens = valuesMasked[0].length - (index + 1);
						if (qtdRestItens > 0 && qtdRestItens % 3 == 0)
							res.push('.');
					}
					return res?.toString().split(',').join('') + ',' + valuesMasked[1];
			}

		} else {
			return '0,00';
		}
	}
}


export const creditCardMask = (/** @type {import("react").ChangeEvent<HTMLInputElement>} */ event) => {
	VMasker(event.target).unMask();
	VMasker(event.target).maskPattern("9999 9999 9999 9999");
}

export const dateMask = (/** @type {import("react").ChangeEvent<HTMLInputElement>} */ event) => {
	VMasker(event.target).unMask();
	VMasker(event.target).maskPattern("99/99/9999");
}
export const dateCardMask = (/** @type {import("react").ChangeEvent<HTMLInputElement>} */ event) => {
	VMasker(event.target).unMask();
	VMasker(event.target).maskPattern("99/99");
}

export const plateMask = (/** @type {import("react").ChangeEvent<HTMLInputElement>} */ event) => {
	let inputValue = event.target.value.toUpperCase();
	inputValue = inputValue.replace(/[^A-Z0-9]/g, '');
	inputValue = inputValue.substring(0, 7);
	const maskedValue = inputValue.replace(/([A-Z]{3})([0-9A-Z]{0,4})/, '$1-$2');
	return maskedValue;
};

export const yearMask = (/** @type {import("react").ChangeEvent<HTMLInputElement>} */ event) => {
	VMasker(event.target).unMask();
	VMasker(event.target).maskPattern(`9999`);
}

export const accountNumberMask = (/** @type {import("react").ChangeEvent<HTMLInputElement>} */ event) => {
	VMasker(event.target).unMask();
	VMasker(event.target).maskPattern(`9999`);
}

