import NycLogo from "../../assets/nyc-fgts-logo.jpeg";
import logoutIcon from "../../assets/logout.svg";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/userContext";
import { useNavigate } from "react-router-dom";
import "./style.css";

type AsideDefaultProps = {};

const AsideDefault = (props: AsideDefaultProps) => {
  const { logout: logoutContext } = useContext(AuthContext);
  const navigate = useNavigate();

  const [isPromoter, setIsPromoter] = useState(false);
  let isStore = sessionStorage.getItem("@store");
  let userInfoStorage = localStorage.getItem("userDetails");
  let userInfo = JSON.parse(userInfoStorage as string);

  useEffect(() => {
    setIsPromoter(JSON.parse(isStore as string));
  }, []);

  const logout = () => {
    localStorage.clear();
    logoutContext();
    navigate("/");
  };

  return (
    <aside className="aside-links">
      <img
        className="nyc-logo-fgts-dash"
        src={NycLogo}
        alt="nyc bank FGTS icon"
      />
      {!isPromoter && (
        <p className="aside-p-links" onClick={() => navigate("/withdraw-fgts")}>
          Simulação saque FGTS
        </p>
      )}

      <p className="aside-p-links" onClick={() => navigate("/search-contract")}>
        Contratos FGTS
      </p>
      <p className="aside-p-links" onClick={() => navigate("/proposal-status")}>
        Acompanhamento de propostas
      </p>

      {isPromoter && (
        <>
          {/* <p
            className="aside-p-links"
            onClick={() => navigate("/manager-area/commission-card-data")}
          >
            Dados de Comissão Cartão
          </p>

          <p
            className="aside-p-links"
            onClick={() => navigate("/manager-area/commission-FGTS-data")}
          >
            Dados de Comissão FGTS
          </p>

          <p
            className="aside-p-links"
            onClick={() => navigate("/manager-area/financial-data")}
          >
            Financeiro
          </p> */}
          {userInfo?.cpf === "114.984.838-36" ||
          userInfo?.cpf === "11498483836" ? (
            <p
              className="aside-p-links"
              onClick={() => navigate("/manager-area/pre-register-list")}
            >
              Lista de pre registrados
            </p>
          ) : (
            false
          )}

          <p
            className="aside-p-links"
            onClick={() => navigate("/manager-area/seller-register")}
          >
            Cadastro de vendedores
          </p>

          <p
            className="aside-p-links"
            onClick={() => navigate("/manager-area/seller-list")}
          >
            Lista de vendedores
          </p>
        </>
      )}

      <span className="logout" onClick={logout}>
        <p className="aside-p-links">Sair</p>
        <img src={logoutIcon} alt="logout icon" style={{ width: "23px" }} />
      </span>
    </aside>
  );
};

export default AsideDefault;
