import { AxiosError } from "axios";
import { REQUEST } from "../request";

const GET_ENABLED_FGTS = async (seller_id: string, token: string) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: `/simulations/get_fgts_enabled?${seller_id}`,
      method: "get",
      token,
    });

    return { result: req.data, status: req.status };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default GET_ENABLED_FGTS;
