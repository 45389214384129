import { AxiosError } from "axios";
import { REQUEST } from "../request";

type BodyRequest = {
  seller: {
    kind_pix: string;
    pix: string;
    store_id: any;
    mobile: string;
    entity_attributes: {
      name: string;
      cpf_cnpj: string;
      has_commission: boolean;
      address_attributes: {
        zip_code: string;
        street: string;
        number: string;
        district: string;
        city: string;
        state: string;
        complement: string;
      };
      commission_bases_attributes: {
        commission_type: string;
        value: string;
        trigger: string;
        base_trigger: string;
      }[];
    };
    users_attributes: {
      cpf: string;
      name: string;
      mobile: string;
      email: string;
      password: string;
    };
  }[];
};

const REGISTER_SELLER = async (data: any, token: string) => {
  try {
    const req = await REQUEST({
      urlBase: "novoSaque",
      endPoint: `/sellers`,
      method: "post",
      token,
      data,
    });

    return {
      result: req.data,
      status: req.status,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return { result: error, status: error.response?.status };
    }
  }
};

export default REGISTER_SELLER;
